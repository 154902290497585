import React, { useState } from "react";
import moment from "moment";
import logoTurkey from "./Picture1.png";
import logoIraq from "./Picture2.png";

import { delay, shift, users, vacations } from "../../db/interfaces";
import _ from "lodash";
import "./style.css";
import { convertToHours, convertToHoursMin } from "./convertToTimeForm";
import { currentP } from "../../db/currentPosition";
import { globalMissionsData } from "../../global/missionList";
import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
import { currentSupervisor } from "../../db/currentSupervisor";
import { currentContractPercentage } from "../../db/currentContractPercentage";
import { calculateVacatedHoursImpact, calculateVacationImpactIn, calculateVacationImpactOut } from "./functions";
import { Container } from "@material-ui/core";
import { currentSalary } from "../../db/currentSalary";
import { currentOffice } from "../../db/currentOffice";
import { currentPaymentType } from "../../db/currentPaymentTypes";

interface Props {
  month: any;
  uid: string;
  userData: users;
  vacationsMap: vacations[];
  projectSN: any;
  budgetLinesSN: any;
  allocationSN: any;
  usersSN: any;
  officeSN: any;
  delaySN: any;
  shiftSN: any;
  activeDelay: boolean;
}
export default function TimeSheetComponent(props: Props) {
  const {
    month,
    uid,
    userData,
    vacationsMap,
    projectSN,
    budgetLinesSN,
    allocationSN,
    usersSN,
    officeSN,
    delaySN,
    shiftSN,
    activeDelay,
  } = props;

  const fullName = userData.firstName + " " + userData.lastName;
  const position = userData.position;
  const location = officeSN?.docs.find((d) => d.id == userData.office)?.data().officeName;
  const missionData = globalMissionsData[globalOfficesData[userData.office].missionId];
  const missionWeekendDays = missionData.weekendDays;
  const publicHolidays = missionData.holidayDates;
  const supervisorFromUser = globalUsersData[userData?.managerUID];
  const supervisorName = globalUsersData[currentSupervisor(uid, month)]?.fullName ?? supervisorFromUser?.fullName;
  const supervisorPosition = globalUsersData[currentSupervisor(uid, month)]?.position ?? supervisorFromUser?.position;

  const shiftsData = shiftSN?.docs
    .map((shift: any) => {
      return { ...shift.data(), id: shift.id };
    })
    .filter((shift: shift) => shift.uid == uid);

  const projectsArray = allocationSN.docs
    .filter((d) => d.data().uid == uid)
    .filter((d) => {
      const startDate = d.data().startDate.toDate();
      return startDate < month.endOf("month");
    })
    .filter((d) => {
      const endDate = d.data().endDate.toDate();
      return month.startOf("month") < endDate;
    })
    .map((a) => {
      return {
        project: a.data().projectId,
        budgetLine: a.data().budgetLineId,
        percentage: a.data().percentage,
        startDate: a.data().startDate,
        endDate: a.data().endDate,
      };
    });
  const BEG =
    projectsArray.length == 1 && moment(projectsArray[0].startDate.toDate()).isAfter(month.startOf("month"), "day")
      ? moment(projectsArray[0].startDate.toDate()).format("DD.MM.YYYY")
      : month.startOf("month").format("DD.MM.YYYY");
  const END =
    projectsArray.length == 1 && moment(projectsArray[0].endDate.toDate()).isBefore(month.endOf("month"), "day")
      ? moment(projectsArray[0].endDate.toDate()).format("DD.MM.YYYY")
      : month.endOf("month").format("DD.MM.YYYY");

  const officeData = globalOfficesData[globalUsersData[uid].office];
  const shiftVacationPerHours = officeData.shiftVacationPerHours;
  const officeStartHour = officeData.workStartTime.toDate();
  const officeEndHour = officeData.workEndTime.toDate();
  var a = moment(officeStartHour, "HH:mm:ss").date(officeStartHour.getDate()); //now
  var b = moment(officeEndHour, "HH:mm:ss").date(officeStartHour.getDate());
  const employmentType =
    globalUsersData[uid].employmentType == undefined ? "fullTime" : globalUsersData[uid].employmentType;
  const partTimeSchedule = globalUsersData[uid].partTimeSchedule;
  const idaAllocationPercentage =
    currentContractPercentage(uid, month) == ""
      ? globalUsersData[uid]?.idaAllocationPercentage
      : currentContractPercentage(uid, month);

  const fullDayMin = b.diff(a, "minutes"); // taken from start and end hours from OFFICE
  const fullDayPercentageMin = (fullDayMin * idaAllocationPercentage) / 100; // calculating mins according to working-hours and percentage of allocation
  const halfDayMin = fullDayMin / 2; // half day time
  const halfDayPercentageMin = (halfDayMin * idaAllocationPercentage) / 100;
  const timeOutMin = a.diff(moment(officeStartHour).startOf("day"), "minutes") + fullDayPercentageMin;
  const timeOutMin9 = a.diff(moment(officeStartHour).startOf("day"), "minutes") + fullDayPercentageMin / 2;
  const timeOutMin13 =
    a.diff(moment(officeStartHour).startOf("day"), "minutes") + halfDayPercentageMin + fullDayPercentageMin / 2;

  const timeInMin9 = a.diff(moment(officeStartHour).startOf("day"), "minutes");
  const timeInMin13 = a.diff(moment(officeStartHour).startOf("day"), "minutes") + halfDayPercentageMin;

  const shiftStartTimeInMin = (type: any) => {
    let startTime;
    if (type == 16) {
      startTime = 990;
    } else if (type == 13) {
      startTime = 1230;
    } else {
      startTime = 510;
    }
    return startTime;
  };

  const shiftEndTimeInMin = (type: any) => {
    let endTime;
    if (type == 6.5) {
      endTime = 900;
    } else if (type == 8) {
      endTime = 990;
    } else if (type == 16) {
      endTime = 510;
    } else if (type == 13) {
      endTime = 510;
    } else if (type == 30.5) {
      endTime = 900;
    } else if (type == 12) {
      endTime = 1230;
    } else {
      endTime = 510;
    }
    return endTime;
  };

  const DelayDates = activeDelay
    ? delaySN.docs
        .filter((d) => d.data().userUID == uid)
        .filter((d) => {
          const delaysData = d?.data() as delay;
          return delaysData.status != "disabled";
        })
        .map((d) => {
          return {
            userUID: d.data()?.userUID,
            day: d.data()?.dayDate,
            min: d.data()?.min,
            month: d.data()?.month, //TODO: add note
            notes: d.data()?.notes,
          };
        })
    : [
        {
          userUID: "",
          day: moment(),
          min: 0,
          month: "",
          notes: "",
        },
      ];
  const VacationDates = vacationsMap
    .filter((vacation) => vacation.employeeUID == uid)
    .filter((vacation) => vacation.status != "Rejected" && vacation.status != "Canceled")
    .map((d) => {
      return {
        start: d.vacationStartOn,
        end: d.vacationEndOn,
        duration: d.vocationPeriodDays,
        type: d.vacationType,
        halfDay: d.halfDay,
      };
    });
  function daysInMonth(m: any) {
    var count = moment().year(m.year()).month(m.month()).daysInMonth();
    var days = [];
    for (var i = 1; i < count + 1; i++) {
      days.push(moment().year(m.year()).month(m.month()).date(i));
    }
    return days;
  }
  var days = daysInMonth(month);

  const empPosition = currentP(uid, month);
  const supPostion =
    currentSupervisor(uid, month) == ""
      ? supervisorPosition
      : globalUsersData[currentSupervisor(uid, month) ?? ""]?.position;

  const countNumberOfDaysBeforeSpecificDate = (
    targetDate: string,
    datesArray: string[]
  ): { count: number; isEven: boolean } => {
    const targetMoment = moment(targetDate, "DD.MM.YYYY");
    let count = 0;

    for (const dateStr of datesArray) {
      const currentDateMoment = moment(dateStr, "DD.MM.YYYY");
      if (currentDateMoment.isBefore(targetMoment, "day")) {
        count++;
      }
    }

    const isEven = count % 2 === 0;

    return { count, isEven };
  };
  let restDayIn = { min: 0, date: null as any };
  let dayInSizeOfMinutes = 0;
  const daysTimeIn = days.map((r) => {
    let MINUTES_IN_DAY: any = 0;
    let workedDay = false;
    switch (employmentType) {
      case "fullTime":
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : timeInMin9;
        const v = VacationDates.map((v) => {
          const HalfDayTimeIn = v.halfDay == "evening" ? timeInMin9 : timeInMin13;
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY = v.duration == 0.5 ? HalfDayTimeIn : 0;
          }
        });
        const d = DelayDates.map((d: any) => {
          const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
          if (
            moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
            !d.notes.toLowerCase().includes("last")
          ) {
            MINUTES_IN_DAY = MINUTES_IN_DAY + d.min;
          }
        });
        const h = publicHolidays.map((v) => {
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == v) {
            MINUTES_IN_DAY = 0;
          }
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022") {
            const HalfDayTimeIn = timeInMin9;
            MINUTES_IN_DAY = HalfDayTimeIn;
          }
          VacationDates.map((vc) => {
            const HalfDayTimeIn = timeInMin13;
            const start = moment(vc.start.toDate()).startOf("day");
            const end = moment(vc.end.toDate()).endOf("day");

            if (
              moment("08-07-2022", "DD-MM-YYYY").isBetween(start, end, undefined, "[]") &&
              moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022"
            ) {
              MINUTES_IN_DAY = 0;
            }
          });
        });

        const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
          moment(project.endDate.toDate()).endOf("day")
        );
        if (
          !moment(r.toDate()).isBetween(
            moment(latestProject?.startDate.toDate()).startOf("day"),
            moment(latestProject?.endDate.toDate()).endOf("day"),
            undefined,
            "[]"
          )
        ) {
          MINUTES_IN_DAY = 0;
        }

        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time
        const timeInMin13PartTime =
          partTimeStartHour.diff(moment(partTimeStartHour).startOf("day"), "minutes") + halfDayMinPartTIme;

        const timeInMinPartTime = partTimeDayDetails.status
          ? partTimeStartHour.diff(moment(partTimeDayDetails.start.toDate()).startOf("day"), "minutes")
          : 0;
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd"))
          ? 0
          : partTimeDayDetails.status
          ? timeInMinPartTime
          : 0;

        const v = VacationDates.map((v) => {
          const HalfDayTimeIn = v.halfDay == "evening" ? timeInMinPartTime : timeInMin13PartTime;
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY = v.duration == 0.5 ? HalfDayTimeIn : 0;
          }
        });
        const d = DelayDates.map((d: any) => {
          const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
          if (
            moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
            !d.notes.toLowerCase().includes("last")
          ) {
            MINUTES_IN_DAY = MINUTES_IN_DAY + d.min;
          }
        });
        const h = publicHolidays.map((v) => {
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == v) {
            MINUTES_IN_DAY = 0;
          }
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022") {
            const HalfDayTimeIn = timeInMinPartTime;
            MINUTES_IN_DAY = HalfDayTimeIn;
          }
          VacationDates.map((vc) => {
            const start = moment(vc.start.toDate()).startOf("day");
            const end = moment(vc.end.toDate()).endOf("day");

            if (
              moment("08-07-2022", "DD-MM-YYYY").isBetween(start, end, undefined, "[]") &&
              moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022"
            ) {
              MINUTES_IN_DAY = 0;
            }
          });
        });

        const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
          moment(project.endDate.toDate()).endOf("day")
        );
        if (
          !moment(r.toDate()).isBetween(
            moment(latestProject?.startDate.toDate()).startOf("day"),
            moment(latestProject?.endDate.toDate()).endOf("day"),
            undefined,
            "[]"
          )
        ) {
          MINUTES_IN_DAY = 0;
        }

        break;
      case "shift":
        const shiftsDaysWithType = _.flatMap(shiftsData, (obj) =>
          _.map(obj.shiftDates, (date) => ({ date, type: obj.type, id: obj.id }))
        );
        const originalShiftsDaysWithType = _.flatMap(shiftsData, (obj) =>
          _.map(obj.OriginalShiftDates, (date) => ({ date, type: obj.type, id: obj.id }))
        );
        const thisDayShiftDetails = shiftsDaysWithType.find(
          (s) => s.date == moment(r.toDate()).format("DD.MM.YYYY").toString()
        );

        if (thisDayShiftDetails) {
          workedDay = true;

          if (thisDayShiftDetails.type == 24) {
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const lastDay = thisDay.clone().subtract(1, "days").format("DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const filteredShifts = shiftsDaysWithType.filter((s) => [24].includes(s.type));
            const nextDayShift = filteredShifts.find((s) => s.date == nextDay);
            const lastDayShift = filteredShifts.find((s) => s.date == lastDay);
            const thisDayShift = filteredShifts.find((s) => s.date == thisDay.format("DD.MM.YYYY"));

            if (
              nextDayShift &&
              lastDayShift &&
              thisDayShift?.id == lastDayShift?.id &&
              thisDayShift?.id == nextDayShift?.id
            ) {
              MINUTES_IN_DAY = 0;
              dayInSizeOfMinutes = 1440;
            } else if (
              nextDayShift &&
              lastDayShift &&
              thisDayShift?.id == lastDayShift?.id &&
              thisDayShift?.id != nextDayShift?.id
            ) {
              MINUTES_IN_DAY = 0;
              dayInSizeOfMinutes = 510;
            } else if (nextDayShift && !lastDayShift) {
              MINUTES_IN_DAY = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayInSizeOfMinutes = 1440;
            } else if (!nextDayShift && lastDayShift) {
              MINUTES_IN_DAY = 0;
              dayInSizeOfMinutes = 510;
            } else if (nextDayShift && lastDayShift && thisDayShift?.id != lastDayShift?.id) {
              MINUTES_IN_DAY = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayInSizeOfMinutes = 1440;
            } else {
              MINUTES_IN_DAY = 0;
            }

            if (
              originalShiftsDaysWithType.find((s) => s.date == thisDayShiftDetails.date) &&
              !originalShiftsDaysWithType.find((s) => s.date == lastDayShift?.date)
            ) {
              MINUTES_IN_DAY = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayInSizeOfMinutes = 1440;
            }

            // delays
            DelayDates.map((d: any) => {
              const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
              if (
                moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
                !d.notes.toLowerCase().includes("last")
              ) {
                MINUTES_IN_DAY = MINUTES_IN_DAY + d.min;
              }
            });

            const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
              moment(project.endDate.toDate()).endOf("day")
            );
            if (
              !moment(r.toDate()).isBetween(
                moment(latestProject?.startDate.toDate()).startOf("day"),
                moment(latestProject?.endDate.toDate()).endOf("day"),
                undefined,
                "[]"
              )
            ) {
              MINUTES_IN_DAY = 0;
            }

            const vacations = calculateVacationImpactIn(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayIn,
              dayInSizeOfMinutes,
              workedDay
            );
            restDayIn = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
            workedDay = vacations.workedDay;
          } else if (thisDayShiftDetails.type == 30.5) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [30.5].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const lastDay = thisDay.clone().subtract(1, "days").format("DD.MM.YYYY");

            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);
            const isShiftThereLastDayShift = shiftsDaysWithType.find((s) => s.date == lastDay);

            if (isShiftThereNextDayShift && !isShiftThereLastDayShift) {
              MINUTES_IN_DAY = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayInSizeOfMinutes = 1440;
            } else {
              MINUTES_IN_DAY = 0;
              dayInSizeOfMinutes = 900;
            }
            DelayDates.map((d: any) => {
              const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
              if (
                moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
                !d.notes.toLowerCase().includes("last")
              ) {
                MINUTES_IN_DAY = MINUTES_IN_DAY + d.min;
              }
            });
            const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
              moment(project.endDate.toDate()).endOf("day")
            );
            if (
              !moment(r.toDate()).isBetween(
                moment(latestProject?.startDate.toDate()).startOf("day"),
                moment(latestProject?.endDate.toDate()).endOf("day"),
                undefined,
                "[]"
              )
            ) {
              MINUTES_IN_DAY = 0;
            }
            const vacations = calculateVacationImpactIn(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayIn,
              dayInSizeOfMinutes,
              workedDay
            );
            restDayIn = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
            workedDay = vacations.workedDay;
          } else if (thisDayShiftDetails.type == 12) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [12].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            // if (previousDates.isEven && isShiftThereNextDayShift) {
            MINUTES_IN_DAY = shiftStartTimeInMin(thisDayShiftDetails.type);
            dayInSizeOfMinutes = shiftEndTimeInMin(thisDayShiftDetails.type);
            // } else {
            //   MINUTES_IN_DAY = 0;
            //   dayInSizeOfMinutes = 510;
            // }
            DelayDates.map((d: any) => {
              const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
              if (
                moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
                !d.notes.toLowerCase().includes("last")
              ) {
                MINUTES_IN_DAY = MINUTES_IN_DAY + d.min;
              }
            });
            const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
              moment(project.endDate.toDate()).endOf("day")
            );
            if (
              !moment(r.toDate()).isBetween(
                moment(latestProject?.startDate.toDate()).startOf("day"),
                moment(latestProject?.endDate.toDate()).endOf("day"),
                undefined,
                "[]"
              )
            ) {
              MINUTES_IN_DAY = 0;
            }
            const vacations = calculateVacationImpactIn(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayIn,
              dayInSizeOfMinutes,
              workedDay
            );
            restDayIn = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
            workedDay = vacations.workedDay;
          } else if (thisDayShiftDetails.type == 16) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [16].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              MINUTES_IN_DAY = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayInSizeOfMinutes = 1440;
            } else {
              MINUTES_IN_DAY = 0;
              dayInSizeOfMinutes = 510;
            }
            DelayDates.map((d: any) => {
              const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
              if (
                moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
                !d.notes.toLowerCase().includes("last")
              ) {
                MINUTES_IN_DAY = MINUTES_IN_DAY + d.min;
              }
            });
            const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
              moment(project.endDate.toDate()).endOf("day")
            );
            if (
              !moment(r.toDate()).isBetween(
                moment(latestProject?.startDate.toDate()).startOf("day"),
                moment(latestProject?.endDate.toDate()).endOf("day"),
                undefined,
                "[]"
              )
            ) {
              MINUTES_IN_DAY = 0;
            }
            const vacations = calculateVacationImpactIn(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayIn,
              dayInSizeOfMinutes,
              workedDay
            );
            restDayIn = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
            workedDay = vacations.workedDay;
          } else if (thisDayShiftDetails.type == 13) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [13].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              MINUTES_IN_DAY = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayInSizeOfMinutes = 1440;
            } else {
              MINUTES_IN_DAY = 0;
              dayInSizeOfMinutes = 510;
            }
            DelayDates.map((d: any) => {
              const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
              if (
                moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
                !d.notes.toLowerCase().includes("last")
              ) {
                MINUTES_IN_DAY = MINUTES_IN_DAY + d.min;
              }
            });
            const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
              moment(project.endDate.toDate()).endOf("day")
            );
            if (
              !moment(r.toDate()).isBetween(
                moment(latestProject?.startDate.toDate()).startOf("day"),
                moment(latestProject?.endDate.toDate()).endOf("day"),
                undefined,
                "[]"
              )
            ) {
              MINUTES_IN_DAY = 0;
            }
            const vacations = calculateVacationImpactIn(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayIn,
              dayInSizeOfMinutes,
              workedDay
            );
            restDayIn = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
            workedDay = vacations.workedDay;
          } else {
            const fullDayMinShiftStart = 8.5 * 60;
            const halfDayMinShift = thisDayShiftDetails.type == 6.5 ? 705 : 750; // half day time

            MINUTES_IN_DAY = shiftStartTimeInMin(thisDayShiftDetails.type);

            VacationDates.map((v) => {
              const HalfDayTimeIn = v.halfDay == "evening" ? fullDayMinShiftStart : halfDayMinShift;
              const start = moment(v.start.toDate()).startOf("day");
              const end = moment(v.end.toDate()).endOf("day");

              if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
                MINUTES_IN_DAY = v.duration == 0.5 ? HalfDayTimeIn : 0;
                workedDay = MINUTES_IN_DAY == 0 ? false : true;
              }
            });
            DelayDates.map((d: any) => {
              const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
              if (
                moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
                !d.notes.toLowerCase().includes("last")
              ) {
                MINUTES_IN_DAY = MINUTES_IN_DAY + d.min;
              }
            });

            const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
              moment(project.endDate.toDate()).endOf("day")
            );
            if (
              !moment(r.toDate()).isBetween(
                moment(latestProject?.startDate.toDate()).startOf("day"),
                moment(latestProject?.endDate.toDate()).endOf("day"),
                undefined,
                "[]"
              )
            ) {
              MINUTES_IN_DAY = 0;
            }
          }
        } else {
          MINUTES_IN_DAY = 0;
        }

        break;

      default:
        workedDay = false;
        break;
    }

    return { MINUTES_IN_DAY, r, workedDay };
  });

  let restDayOut = { min: 0, date: null as any };
  let dayOutSizeOfMinutes = 0;
  let day_log_in_min = 0;

  const daysTimeOut = days.map((r) => {
    let MINUTES_IN_DAY: any = 0;
    let workedDay = false;

    switch (employmentType) {
      case "fullTime":
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : timeOutMin;
        const v = VacationDates.map((v) => {
          const HalfDayTimeOut =
            v.halfDay == "evening" ? timeOutMin9 : v.halfDay == "morning" ? timeOutMin13 : timeOutMin;
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY = v.duration == 0.5 ? HalfDayTimeOut : 0;
          }
        });
        const d = DelayDates.map((d: any) => {
          const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
          if (
            moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
            d.notes.toLowerCase().includes("last") &&
            MINUTES_IN_DAY != 0
          ) {
            MINUTES_IN_DAY = MINUTES_IN_DAY - d.min;
          }
        });

        const h = publicHolidays.map((v) => {
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == v) {
            MINUTES_IN_DAY = 0;
          }

          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022") {
            const HalfDayTimeOut = timeOutMin9;
            MINUTES_IN_DAY = HalfDayTimeOut;
          }
          VacationDates.map((vc) => {
            const HalfDayTimeIn = timeInMin13;
            const start = moment(vc.start.toDate()).startOf("day");
            const end = moment(vc.end.toDate()).endOf("day");

            if (
              moment("08-07-2022", "DD-MM-YYYY").isBetween(start, end, undefined, "[]") &&
              moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022"
            ) {
              MINUTES_IN_DAY = 0;
            }
          });
        });

        const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
          moment(project.endDate.toDate()).endOf("day")
        );
        if (
          !moment(r.toDate()).isBetween(
            moment(latestProject?.startDate.toDate()).startOf("day"),
            moment(latestProject?.endDate.toDate()).endOf("day"),
            undefined,
            "[]"
          )
        ) {
          MINUTES_IN_DAY = 0;
        }
        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time

        const timeOutMin9PartTime =
          partTimeStartHour.diff(moment(partTimeStartHour).startOf("day"), "minutes") + fullDayMinPartTime / 2;
        const timeOutMin13PartTime =
          partTimeStartHour.diff(moment(partTimeStartHour).startOf("day"), "minutes") +
          halfDayMinPartTIme +
          fullDayMinPartTime / 2;

        const timeOutMinPartTime = partTimeDayDetails.status
          ? partTimeEndHour.diff(moment(partTimeDayDetails.end.toDate()).startOf("day"), "minutes")
          : 0;
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd"))
          ? 0
          : partTimeDayDetails.status
          ? timeOutMinPartTime
          : 0;

        const v = VacationDates.map((v) => {
          const HalfDayTimeOut =
            v.halfDay == "evening" ? timeOutMin9PartTime : v.halfDay == "morning" ? timeOutMin13PartTime : 0;
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY = v.duration == 0.5 ? HalfDayTimeOut : 0;
          }
        });
        const d = DelayDates.map((d: any) => {
          const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
          if (
            moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
            d.notes.toLowerCase().includes("last") &&
            MINUTES_IN_DAY != 0
          ) {
            MINUTES_IN_DAY = MINUTES_IN_DAY - d.min;
          }
        });

        const h = publicHolidays.map((v) => {
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == v) {
            MINUTES_IN_DAY = 0;
          }

          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022") {
            const HalfDayTimeOut = timeOutMin9PartTime;
            MINUTES_IN_DAY = HalfDayTimeOut;
          }
          VacationDates.map((vc) => {
            const start = moment(vc.start.toDate()).startOf("day");
            const end = moment(vc.end.toDate()).endOf("day");

            if (
              moment("08-07-2022", "DD-MM-YYYY").isBetween(start, end, undefined, "[]") &&
              moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022"
            ) {
              MINUTES_IN_DAY = 0;
            }
          });
        });

        const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
          moment(project.endDate.toDate()).endOf("day")
        );
        if (
          !moment(r.toDate()).isBetween(
            moment(latestProject?.startDate.toDate()).startOf("day"),
            moment(latestProject?.endDate.toDate()).endOf("day"),
            undefined,
            "[]"
          )
        ) {
          MINUTES_IN_DAY = 0;
        }

        break;
      case "shift":
        const shiftsDaysWithType = _.flatMap(shiftsData, (obj) =>
          _.map(obj.shiftDates, (date) => ({
            date,
            type: obj.type,
            originalShifts: obj.OriginalShiftDates,
            id: obj.id,
          }))
        );

        const originalShiftsDaysWithType = _.flatMap(shiftsData, (obj) =>
          _.map(obj.OriginalShiftDates, (date) => ({ date, type: obj.type, id: obj.id }))
        );
        const thisDayShiftDetails = shiftsDaysWithType.find(
          (s) => s.date == moment(r.toDate()).format("DD.MM.YYYY").toString()
        );

        if (thisDayShiftDetails) {
          workedDay = true;

          if (thisDayShiftDetails.type == 24) {
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const lastDay = thisDay.clone().subtract(1, "days").format("DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const filteredShifts = shiftsDaysWithType.filter((s) => [24].includes(s.type));
            const nextDayShift = filteredShifts.find((s) => s.date == nextDay);
            const lastDayShift = filteredShifts.find((s) => s.date == lastDay);
            const thisDayShift = filteredShifts.find((s) => s.date == thisDay.format("DD.MM.YYYY"));

            if (nextDayShift && lastDayShift && thisDayShift?.id == nextDayShift?.id) {
              MINUTES_IN_DAY = 0;
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 1440;
            } else if (nextDayShift && !lastDayShift) {
              MINUTES_IN_DAY = 0;
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else if (!nextDayShift && lastDayShift) {
              MINUTES_IN_DAY = shiftEndTimeInMin(thisDayShiftDetails.type);
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            } else if (nextDayShift && lastDayShift && thisDayShift?.id != nextDayShift?.id) {
              MINUTES_IN_DAY = shiftEndTimeInMin(thisDayShiftDetails.type);
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            } else {
              MINUTES_IN_DAY = 0;
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 1440;
            }

            if (
              !originalShiftsDaysWithType.find((s) => s.date == thisDayShiftDetails.date) &&
              originalShiftsDaysWithType.find((s) => s.date == lastDayShift?.date)
            ) {
              MINUTES_IN_DAY = shiftEndTimeInMin(thisDayShiftDetails.type);
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            }

            DelayDates.map((d: any) => {
              const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
              if (
                moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
                d.notes.toLowerCase().includes("last") &&
                MINUTES_IN_DAY != 0
              ) {
                MINUTES_IN_DAY = MINUTES_IN_DAY - d.min;
              }
            });

            const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
              moment(project.endDate.toDate()).endOf("day")
            );
            if (
              !moment(r.toDate()).isBetween(
                moment(latestProject?.startDate.toDate()).startOf("day"),
                moment(latestProject?.endDate.toDate()).endOf("day"),
                undefined,
                "[]"
              )
            ) {
              MINUTES_IN_DAY = 0;
            }

            const vacations = calculateVacationImpactOut(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              workedDay
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
            workedDay = vacations.workedDay;
          } else if (thisDayShiftDetails.type == 30.5) {
            const filteredShifts = shiftsDaysWithType.filter((s) => [30.5].includes(s.type));
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = filteredShifts.find((s) => s.date == nextDay);

            if (isShiftThereNextDayShift) {
              MINUTES_IN_DAY = 0;
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              MINUTES_IN_DAY = shiftEndTimeInMin(thisDayShiftDetails.type);
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 900;
            }

            DelayDates.map((d: any) => {
              const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
              if (
                moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
                d.notes.toLowerCase().includes("last") &&
                MINUTES_IN_DAY != 0
              ) {
                MINUTES_IN_DAY = MINUTES_IN_DAY - d.min;
              }
            });

            const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
              moment(project.endDate.toDate()).endOf("day")
            );
            if (
              !moment(r.toDate()).isBetween(
                moment(latestProject?.startDate.toDate()).startOf("day"),
                moment(latestProject?.endDate.toDate()).endOf("day"),
                undefined,
                "[]"
              )
            ) {
              MINUTES_IN_DAY = 0;
            }

            const vacations = calculateVacationImpactOut(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              workedDay
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
            workedDay = vacations.workedDay;
          } else if (thisDayShiftDetails.type == 12) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [12].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            // if (previousDates.isEven && isShiftThereNextDayShift) {
            //   MINUTES_IN_DAY = 0;
            //   day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
            //   dayOutSizeOfMinutes = 1440;
            // } else {
            MINUTES_IN_DAY = shiftEndTimeInMin(thisDayShiftDetails.type);
            day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
            dayOutSizeOfMinutes = 1230;
            // }
            DelayDates.map((d: any) => {
              const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
              if (
                moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
                d.notes.toLowerCase().includes("last") &&
                MINUTES_IN_DAY != 0
              ) {
                MINUTES_IN_DAY = MINUTES_IN_DAY - d.min;
              }
            });

            const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
              moment(project.endDate.toDate()).endOf("day")
            );
            if (
              !moment(r.toDate()).isBetween(
                moment(latestProject?.startDate.toDate()).startOf("day"),
                moment(latestProject?.endDate.toDate()).endOf("day"),
                undefined,
                "[]"
              )
            ) {
              MINUTES_IN_DAY = 0;
            }

            const vacations = calculateVacationImpactOut(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              workedDay
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
            workedDay = vacations.workedDay;
          } else if (thisDayShiftDetails.type == 16) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [16].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              MINUTES_IN_DAY = 0;
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              MINUTES_IN_DAY = shiftEndTimeInMin(thisDayShiftDetails.type);
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            }
            DelayDates.map((d: any) => {
              const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
              if (
                moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
                d.notes.toLowerCase().includes("last") &&
                MINUTES_IN_DAY != 0
              ) {
                MINUTES_IN_DAY = MINUTES_IN_DAY - d.min;
              }
            });

            const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
              moment(project.endDate.toDate()).endOf("day")
            );
            if (
              !moment(r.toDate()).isBetween(
                moment(latestProject?.startDate.toDate()).startOf("day"),
                moment(latestProject?.endDate.toDate()).endOf("day"),
                undefined,
                "[]"
              )
            ) {
              MINUTES_IN_DAY = 0;
            }

            const vacations = calculateVacationImpactOut(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              workedDay
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
            workedDay = vacations.workedDay;
          } else if (thisDayShiftDetails.type == 13) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [13].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              MINUTES_IN_DAY = 0;
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              MINUTES_IN_DAY = shiftEndTimeInMin(thisDayShiftDetails.type);
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            }
            DelayDates.map((d: any) => {
              const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
              if (
                moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
                d.notes.toLowerCase().includes("last") &&
                MINUTES_IN_DAY != 0
              ) {
                MINUTES_IN_DAY = MINUTES_IN_DAY - d.min;
              }
            });

            const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
              moment(project.endDate.toDate()).endOf("day")
            );
            if (
              !moment(r.toDate()).isBetween(
                moment(latestProject?.startDate.toDate()).startOf("day"),
                moment(latestProject?.endDate.toDate()).endOf("day"),
                undefined,
                "[]"
              )
            ) {
              MINUTES_IN_DAY = 0;
            }

            const vacations = calculateVacationImpactOut(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              workedDay
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
            workedDay = vacations.workedDay;
          } else {
            const fullDayMinShiftStart = 8.5 * 60;

            MINUTES_IN_DAY = shiftEndTimeInMin(thisDayShiftDetails.type);

            VacationDates.map((v) => {
              const HalfDayTimeOut =
                v.halfDay == "evening"
                  ? fullDayMinShiftStart + (thisDayShiftDetails.type * 60) / 2
                  : fullDayMinShiftStart + thisDayShiftDetails.type * 60;
              const start = moment(v.start.toDate()).startOf("day");
              const end = moment(v.end.toDate()).endOf("day");

              if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
                MINUTES_IN_DAY = v.duration == 0.5 ? HalfDayTimeOut : 0;
                workedDay = MINUTES_IN_DAY == 0 ? false : true;
              }
            });
            DelayDates.map((d: any) => {
              const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
              if (
                moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
                d.notes.toLowerCase().includes("last") &&
                MINUTES_IN_DAY != 0
              ) {
                MINUTES_IN_DAY = MINUTES_IN_DAY - d.min;
              }
            });

            const latestProject = _.maxBy(projectsArray ?? [], (project: any) =>
              moment(project.endDate.toDate()).endOf("day")
            );
            if (
              !moment(r.toDate()).isBetween(
                moment(latestProject?.startDate.toDate()).startOf("day"),
                moment(latestProject?.endDate.toDate()).endOf("day"),
                undefined,
                "[]"
              )
            ) {
              MINUTES_IN_DAY = 0;
            }
          }
        } else {
          MINUTES_IN_DAY = 0;
        }

        break;

      default:
        workedDay = false;

        break;
    }

    return { MINUTES_IN_DAY, r, workedDay };
  });

  const daysAnnual = days.map((r) => {
    let MINUTES_IN_DAY: any = 0;

    switch (employmentType) {
      case "fullTime":
        VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type == "Annual Leave" ? (v.duration == 0.5 ? halfDayPercentageMin : fullDayPercentageMin) : 0;
          }
        });
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;

        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time
        VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type == "Annual Leave" ? (v.duration == 0.5 ? halfDayMinPartTIme : fullDayMinPartTime) : 0;
          }
        });
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;

        break;
      case "shift":
        const shiftsDaysWithType = _.flatMap(shiftsData, (obj) =>
          _.map(obj.shiftDates, (date) => ({ date, type: obj.type }))
        );

        const thisDayShiftDetails = shiftsDaysWithType.find(
          (s) => s.date == moment(r.toDate()).format("DD.MM.YYYY").toString()
        );

        if (thisDayShiftDetails) {
          if (thisDayShiftDetails.type == 24) {
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const lastDay = thisDay.clone().subtract(1, "days").format("DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");

            if (
              shiftsDaysWithType.find((s) => s.date == nextDay) &&
              shiftsDaysWithType.find((s) => s.date == lastDay)
            ) {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 1440;
            } else if (
              shiftsDaysWithType.find((s) => s.date == nextDay) &&
              !shiftsDaysWithType.find((s) => s.date == lastDay)
            ) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else if (
              !shiftsDaysWithType.find((s) => s.date == nextDay) &&
              shiftsDaysWithType.find((s) => s.date == lastDay)
            ) {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 1440;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Annual Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 30.5) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [30.5].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 900;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Annual Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 12) {
            day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
            dayOutSizeOfMinutes = shiftEndTimeInMin(thisDayShiftDetails.type);

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Annual Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 16) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [16].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Annual Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 13) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [13].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Annual Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else {
            const fullDayMin = thisDayShiftDetails.type * 60;
            const halfDayMin = fullDayMin / 2;
            const v = VacationDates.map((v) => {
              const start = moment(v.start.toDate()).startOf("day");
              const end = moment(v.end.toDate()).endOf("day");

              if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
                MINUTES_IN_DAY = v.type == "Annual Leave" ? (v.duration == 0.5 ? halfDayMin : fullDayMin) : 0;
              }
            });
          }
        } else {
          MINUTES_IN_DAY = 0;
        }

        break;
      default:
        MINUTES_IN_DAY = 0;
        break;
    }

    return { MINUTES_IN_DAY, r };
  });
  const daysSick = days.map((r) => {
    let MINUTES_IN_DAY: any = 0;

    switch (employmentType) {
      case "fullTime":
        VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type == "Sick Leave" ? (v.duration == 0.5 ? halfDayPercentageMin : fullDayPercentageMin) : 0;
          }
        });
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;

        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time
        VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY = v.type == "Sick Leave" ? (v.duration == 0.5 ? halfDayMinPartTIme : fullDayMinPartTime) : 0;
          }
        });
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;

        break;
      case "shift":
        const shiftsDaysWithType = _.flatMap(shiftsData, (obj) =>
          _.map(obj.shiftDates, (date) => ({ date, type: obj.type }))
        );

        const thisDayShiftDetails = shiftsDaysWithType.find(
          (s) => s.date == moment(r.toDate()).format("DD.MM.YYYY").toString()
        );

        if (thisDayShiftDetails) {
          if (thisDayShiftDetails.type == 24) {
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const lastDay = thisDay.clone().subtract(1, "days").format("DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");

            if (
              shiftsDaysWithType.find((s) => s.date == nextDay) &&
              shiftsDaysWithType.find((s) => s.date == lastDay)
            ) {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 1440;
            } else if (
              shiftsDaysWithType.find((s) => s.date == nextDay) &&
              !shiftsDaysWithType.find((s) => s.date == lastDay)
            ) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else if (
              !shiftsDaysWithType.find((s) => s.date == nextDay) &&
              shiftsDaysWithType.find((s) => s.date == lastDay)
            ) {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 1440;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Sick Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 12) {
            day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
            dayOutSizeOfMinutes = shiftEndTimeInMin(thisDayShiftDetails.type);

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Sick Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 16) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [16].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Sick Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 13) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [13].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Sick Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 30.5) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [30.5].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 900;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Sick Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else {
            const fullDayMin = thisDayShiftDetails.type * 60;
            const halfDayMin = fullDayMin / 2;
            const v = VacationDates.map((v) => {
              const start = moment(v.start.toDate()).startOf("day");
              const end = moment(v.end.toDate()).endOf("day");

              if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
                MINUTES_IN_DAY = v.type == "Sick Leave" ? (v.duration == 0.5 ? halfDayMin : fullDayMin) : 0;
              }
            });
          }
        } else {
          MINUTES_IN_DAY = 0;
        }

        break;
      default:
        MINUTES_IN_DAY = 0;
        break;
    }

    return { MINUTES_IN_DAY, r };
  });
  const daysOther = days.map((r) => {
    let MINUTES_IN_DAY: any = 0;
    switch (employmentType) {
      case "fullTime":
        VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type !== "Sick Leave" &&
              v.type !== "Annual Leave" &&
              v.type !== "Unpaid Leave" &&
              v.type !== "Maternity Leave"
                ? v.duration == 0.5
                  ? halfDayPercentageMin
                  : fullDayPercentageMin
                : 0;
          }
        });
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;

        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time
        VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type !== "Sick Leave" &&
              v.type !== "Annual Leave" &&
              v.type !== "Unpaid Leave" &&
              v.type !== "Maternity Leave"
                ? v.duration == 0.5
                  ? halfDayMinPartTIme
                  : fullDayMinPartTime
                : 0;
          }
        });
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;

        break;
      case "shift":
        const shiftsDaysWithType = _.flatMap(shiftsData, (obj) =>
          _.map(obj.shiftDates, (date) => ({ date, type: obj.type }))
        );

        const thisDayShiftDetails = shiftsDaysWithType.find(
          (s) => s.date == moment(r.toDate()).format("DD.MM.YYYY").toString()
        );

        if (thisDayShiftDetails) {
          if (thisDayShiftDetails.type == 24) {
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const lastDay = thisDay.clone().subtract(1, "days").format("DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");

            if (
              shiftsDaysWithType.find((s) => s.date == nextDay) &&
              shiftsDaysWithType.find((s) => s.date == lastDay)
            ) {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 1440;
            } else if (
              shiftsDaysWithType.find((s) => s.date == nextDay) &&
              !shiftsDaysWithType.find((s) => s.date == lastDay)
            ) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else if (
              !shiftsDaysWithType.find((s) => s.date == nextDay) &&
              shiftsDaysWithType.find((s) => s.date == lastDay)
            ) {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 1440;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Other Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 12) {
            day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
            dayOutSizeOfMinutes = shiftEndTimeInMin(thisDayShiftDetails.type);

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Other Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 16) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [16].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Other Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 13) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [13].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Other Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 30.5) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [30.5].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 900;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Other Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else {
            const fullDayMin = thisDayShiftDetails.type * 60;
            const halfDayMin = fullDayMin / 2;
            const v = VacationDates.map((v) => {
              const start = moment(v.start.toDate()).startOf("day");
              const end = moment(v.end.toDate()).endOf("day");

              if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
                MINUTES_IN_DAY =
                  v.type !== "Sick Leave" &&
                  v.type !== "Annual Leave" &&
                  v.type !== "Unpaid Leave" &&
                  v.type !== "Maternity Leave"
                    ? v.duration == 0.5
                      ? halfDayMin
                      : fullDayMin
                    : 0;
              }
            });
          }
        } else {
          MINUTES_IN_DAY = 0;
        }

        break;
      default:
        MINUTES_IN_DAY = 0;
        break;
    }

    return { MINUTES_IN_DAY, r };
  });

  const daysPublicHolidays = days.map((r) => {
    //TODO:
    let MINUTES_IN_DAY = 0;
    switch (employmentType) {
      case "fullTime":
        const h = publicHolidays.map((v) => {
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == v) {
            MINUTES_IN_DAY = fullDayPercentageMin;
          }
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022") {
            MINUTES_IN_DAY = halfDayPercentageMin;
          }
        });
        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time
        const h = publicHolidays.map((v) => {
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == v) {
            MINUTES_IN_DAY = fullDayMinPartTime;
          }
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022") {
            MINUTES_IN_DAY = halfDayMinPartTIme;
          }
        });
        break;
      case "shift":
        break;
      default:
        break;
    }

    MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;
    return { MINUTES_IN_DAY, r };
  });
  const daysUnpaid = days.map((r) => {
    let MINUTES_IN_DAY: any = 0;

    switch (employmentType) {
      case "fullTime":
        VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type == "Unpaid Leave" || v.type == "Maternity Leave"
                ? v.duration == 0.5
                  ? halfDayPercentageMin
                  : fullDayPercentageMin
                : 0;
          }
          MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;
        });
        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time
        VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type == "Unpaid Leave" || v.type == "Maternity Leave"
                ? v.duration == 0.5
                  ? halfDayMinPartTIme
                  : fullDayMinPartTime
                : 0;
          }
          MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;
        });
        break;
      case "shift":
        const shiftsDaysWithType = _.flatMap(shiftsData, (obj) =>
          _.map(obj.shiftDates, (date) => ({ date, type: obj.type }))
        );

        const thisDayShiftDetails = shiftsDaysWithType.find(
          (s) => s.date == moment(r.toDate()).format("DD.MM.YYYY").toString()
        );

        if (thisDayShiftDetails) {
          if (thisDayShiftDetails.type == 24) {
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const lastDay = thisDay.clone().subtract(1, "days").format("DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");

            if (
              shiftsDaysWithType.find((s) => s.date == nextDay) &&
              shiftsDaysWithType.find((s) => s.date == lastDay)
            ) {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 1440;
            } else if (
              shiftsDaysWithType.find((s) => s.date == nextDay) &&
              !shiftsDaysWithType.find((s) => s.date == lastDay)
            ) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else if (
              !shiftsDaysWithType.find((s) => s.date == nextDay) &&
              shiftsDaysWithType.find((s) => s.date == lastDay)
            ) {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 1440;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Unpaid Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 12) {
            day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
            dayOutSizeOfMinutes = shiftEndTimeInMin(thisDayShiftDetails.type);

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Unpaid Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 16) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [16].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Unpaid Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 13) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [13].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 510;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Unpaid Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else if (thisDayShiftDetails.type == 30.5) {
            const previousDates = countNumberOfDaysBeforeSpecificDate(
              moment(r.toDate()).format("DD.MM.YYYY").toString(),
              shiftsDaysWithType.filter((s) => [30.5].includes(s.type)).map((s) => s.date)
            );
            const thisDay = moment(r.toDate(), "DD.MM.YYYY");
            const nextDay = thisDay.clone().add(1, "days").format("DD.MM.YYYY");
            const isShiftThereNextDayShift = shiftsDaysWithType.find((s) => s.date == nextDay);

            if (previousDates.isEven && isShiftThereNextDayShift) {
              day_log_in_min = shiftStartTimeInMin(thisDayShiftDetails.type);
              dayOutSizeOfMinutes = 1440;
            } else {
              day_log_in_min = 0;
              dayOutSizeOfMinutes = 900;
            }

            const vacations = calculateVacatedHoursImpact(
              r,
              VacationDates,
              MINUTES_IN_DAY,
              shiftVacationPerHours,
              restDayOut,
              dayOutSizeOfMinutes,
              day_log_in_min,
              "Unpaid Leave"
            );
            restDayOut = vacations.rest;
            MINUTES_IN_DAY = vacations.MINUTES_IN_DAY;
          } else {
            const fullDayMin = thisDayShiftDetails.type * 60;
            const halfDayMin = fullDayMin / 2;
            VacationDates.map((v) => {
              const start = moment(v.start.toDate()).startOf("day");
              const end = moment(v.end.toDate()).endOf("day");

              if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
                MINUTES_IN_DAY = v.type == "Sick Leave" ? (v.duration == 0.5 ? halfDayMin : fullDayMin) : 0;
              }
            });
          }
        } else {
          MINUTES_IN_DAY = 0;
        }

        break;

      default:
        break;
    }

    return { MINUTES_IN_DAY, r };
  });

  //======> Calculating Section of ALL leaves + Worked Hours <==========

  const timeOut = daysTimeOut;
  const timeIn = daysTimeIn;

  const TimeOut_Minus_TimeIn = timeIn.map((timeIn, index) => {
    let num;

    switch (employmentType) {
      case "fullTime":
        if (timeOut[index] === timeIn) {
          if (timeIn.MINUTES_IN_DAY === 0 && timeOut[index].MINUTES_IN_DAY === 0) {
            num = 0; // 0 minutes
          } else {
            num = 1440; // 24 hours
          }
        } else if (timeOut[index].MINUTES_IN_DAY < timeIn.MINUTES_IN_DAY) {
          num = timeOut[index].MINUTES_IN_DAY + 1440 - timeIn.MINUTES_IN_DAY;
        } else {
          num = timeOut[index].MINUTES_IN_DAY - timeIn.MINUTES_IN_DAY;
        }
        return { d: timeIn.r, num: num };

      case "partTime":
        if (timeOut[index].MINUTES_IN_DAY === timeIn.MINUTES_IN_DAY) {
          if (timeIn.MINUTES_IN_DAY === 0 && timeOut[index].MINUTES_IN_DAY === 0) {
            num = 0; // 0 minutes
          } else {
            num = 1440; // 24 hours
          }
        } else if (timeOut[index].MINUTES_IN_DAY < timeIn.MINUTES_IN_DAY) {
          num = timeOut[index].MINUTES_IN_DAY + 1440 - timeIn.MINUTES_IN_DAY;
        } else {
          num = timeOut[index].MINUTES_IN_DAY - timeIn.MINUTES_IN_DAY;
        }
        return { d: timeIn.r, num: num };

      case "shift":
        if (timeOut[index].MINUTES_IN_DAY === timeIn.MINUTES_IN_DAY) {
          if (timeIn.MINUTES_IN_DAY == 0 && timeOut[index].MINUTES_IN_DAY == 0 && timeIn.workedDay) {
            num = 1440;
          } else {
            num = 0; // 24 hours
          }
        } else if (timeOut[index].MINUTES_IN_DAY < timeIn.MINUTES_IN_DAY) {
          num = timeOut[index].MINUTES_IN_DAY + 1440 - timeIn.MINUTES_IN_DAY;
        } else {
          num = timeOut[index].MINUTES_IN_DAY - timeIn.MINUTES_IN_DAY;
        }
        return { d: timeIn.r, num: num, workedDay: timeIn.workedDay };

      default:
        if (timeOut[index] === timeIn) {
          if (timeIn.MINUTES_IN_DAY === 0 && timeOut[index].MINUTES_IN_DAY === 0) {
            num = 0; // 0 minutes
          } else {
            num = 1440; // 24 hours
          }
        } else if (timeOut[index].MINUTES_IN_DAY < timeIn.MINUTES_IN_DAY) {
          num = timeOut[index].MINUTES_IN_DAY + 1440 - timeIn.MINUTES_IN_DAY;
        } else {
          num = timeOut[index].MINUTES_IN_DAY - timeIn.MINUTES_IN_DAY;
        }
        return { d: timeIn.r, num: num };
    }
  });

  const AnnalLeave_Plus_SickLeave = days.map((d) => {
    const num = 0;
    return { d, num };
  });
  const annal = daysAnnual.map((d) => d.MINUTES_IN_DAY);
  const sick = daysSick.map((d) => d.MINUTES_IN_DAY);
  for (let i = 0; i < timeIn.length; i++) {
    AnnalLeave_Plus_SickLeave[i].num = annal[i] + sick[i];
  }
  const OtherLeave_Plus_PublicHoliday = days.map((d) => {
    const num = 0;
    return { d, num };
  });
  const other = daysOther.map((d) => d.MINUTES_IN_DAY);
  const holidays = daysPublicHolidays.map((d) => d.MINUTES_IN_DAY);
  for (let i = 0; i < timeIn.length; i++) {
    OtherLeave_Plus_PublicHoliday[i].num = other[i] + holidays[i];
  }

  const Total_Annual_Sick_Other_Public = days.map((d) => {
    const num = 0;
    return { d, num };
  });
  const as = AnnalLeave_Plus_SickLeave.map((d) => d.num);
  const ot = OtherLeave_Plus_PublicHoliday.map((d) => d.num);
  for (let i = 0; i < timeIn.length; i++) {
    Total_Annual_Sick_Other_Public[i].num = ot[i] + as[i];
  }

  const TotalLeaves_Plus_WorkedHours = days.map((d) => {
    const num = 0;
    return { d, num };
  });
  const worked = TimeOut_Minus_TimeIn.map((d) => d.num);
  const leaves = Total_Annual_Sick_Other_Public.map((d) => d.num);
  for (let i = 0; i < timeIn.length; i++) {
    TotalLeaves_Plus_WorkedHours[i].num = worked[i] + leaves[i];
  }

  //======> End Calculating Section of ALL leaves + Worked Hours <==========

  const daysTotal = TotalLeaves_Plus_WorkedHours.map((d) => {
    let MINUTES_IN_DAY = d.num;
    // moment(d.d.toDate()).format("dd") == missionWeekendDays[0] ||
    // moment(d.d.toDate()).format("dd") == missionWeekendDays[1]
    //   ? d.num
    //   : d.num;

    const v = VacationDates.map((v) => {
      const start = moment(v.start.toDate()).startOf("day");
      const end = moment(v.end.toDate()).endOf("day");

      if (moment(d.d.toDate()).isBetween(start, end, undefined, "[]")) {
        MINUTES_IN_DAY = v.type == "Unpaid Leave" ? 0 : d.num;
      }
    });
    const r = d.d;
    return { MINUTES_IN_DAY, r };
  });

  const totalCellsProject = projectsArray.map((project) => {
    const { startDate, endDate, percentage } = project;
    const start = moment(startDate.toDate()).startOf("day");
    const end = moment(endDate.toDate()).endOf("day");

    const arr = daysTotal.map((col) => {
      if (moment(col.r.toDate()).isBetween(start, end, undefined, "[]")) {
        let MINUTES_IN_DAY = (col.MINUTES_IN_DAY * percentage) / 100;
        return MINUTES_IN_DAY;
      } else {
        return 0;
      }
    });
    return arr;
  });

  const newTotalCell = days.map((d) => 0);
  for (let i = 0; i < totalCellsProject.length; i++) {
    for (let j = 0; j < newTotalCell.length; j++) {
      //0 to 2
      newTotalCell[j] = newTotalCell[j] + totalCellsProject[i][j];
    }
  }
  const TOTAL_PROJECT_CELL = _.sum(newTotalCell);
  const hours = _.sumBy(
    daysTotal.filter((t: any) => t.MINUTES_IN_DAY != 0),
    (d) => d.MINUTES_IN_DAY
  );
  const percentage = _.sumBy(projectsArray, (d) => d.percentage);
  const totalWorkedHours = _.sumBy(
    TimeOut_Minus_TimeIn.filter((t) => t.d != 0),
    (d) => d.num
  );
  const totalAnnualHours = _.sumBy(daysAnnual, (d) => d.MINUTES_IN_DAY);
  const totalSickHours = _.sumBy(daysSick, (d) => d.MINUTES_IN_DAY);
  const totalOtherHours = _.sumBy(daysOther, (d) => d.MINUTES_IN_DAY);
  const totalPublicHours = _.sumBy(daysPublicHolidays, (d) => d.MINUTES_IN_DAY);
  const totalUnpaidHours = _.sumBy(daysUnpaid, (d) => d.MINUTES_IN_DAY);

  return (
    <div>
      <div id="timeSheet-component">
        <table style={{ width: "80%" }}>
          <tr className={"row"}>
            <th colSpan={9} className={"logos"}>
              <img
                style={{ width: "150px", marginBottom: "3%", marginTop: "3%", marginLeft: "5%" }}
                src={globalOfficesData[globalUsersData[uid].office].cacheMissionName == "IRAQ" ? logoIraq : logoTurkey}
              />
            </th>
            <th colSpan={13} className={"textField"}>
              <h2>IDA</h2>
              <h4>{month.format("MMM.YYYY")}</h4>
              <h5>TIMESHEET</h5>
            </th>
            <th colSpan={13} className={"logos"}>
              <img
                style={{ width: "150px", marginBottom: "3%", marginTop: "3%", marginRight: "5%", float: "right" }}
                src={globalOfficesData[globalUsersData[uid].office].cacheMissionName == "IRAQ" ? logoIraq : logoTurkey}
              />
            </th>
          </tr>

          <tr className={"row"}>
            <th colSpan={1} className={"titleText"}>
              <h4>Name</h4>
            </th>
            <th colSpan={11} className={"inputText"}>
              <h4>{fullName}</h4>
            </th>
            <th colSpan={8} className={"titleText"}>
              <h4>Position</h4>
            </th>
            <th colSpan={15} className={"inputText"}>
              <h4>{empPosition == "" ? position : empPosition}</h4>
            </th>
          </tr>

          <tr className={"row"}>
            <th colSpan={1} rowSpan={2} className={"titleText"}>
              <h4>Month</h4>
            </th>
            <th colSpan={1} className={"inputText"}>
              <h4>BEG</h4>
            </th>
            <th colSpan={10} className={"inputText"}>
              <h4>{BEG}</h4>
            </th>
            <th colSpan={8} className={"titleText"}>
              <h4>Location</h4>
            </th>
            <th colSpan={15} className={"inputText"}>
              <h4>{location}</h4>
            </th>
          </tr>

          <tr className={"row"}>
            <th colSpan={1} className={"inputText"}>
              <h4>END</h4>
            </th>
            <th colSpan={10} className={"inputText"}>
              <h4>{END}</h4>
            </th>
            <th colSpan={8} className={"titleText"}>
              <h4>Supervisor's Name</h4>
            </th>
            <th colSpan={6} className={"inputText"}>
              <h4>{supervisorName}</h4>
            </th>
            <th colSpan={3} className={"titleText"}>
              <h4>Title</h4>
            </th>
            <th colSpan={7} className={"inputText"}>
              <h4>{supPostion}</h4>
            </th>
          </tr>

          <tr className={"row"}>
            <th colSpan={1} className={"titleText"}>
              <h4>Employee's Signature and Date</h4>
            </th>
            <th colSpan={11} className={"inputText"}>
              <h4></h4>
            </th>
            <th colSpan={8} className={"titleText"}>
              <h4>Supervisor's Signature and Date</h4>
            </th>
            <th colSpan={15} className={"inputText"}>
              <h4></h4>
            </th>
          </tr>

          <tr className={"row"}>
            <th colSpan={3} className={"titleText"}>
              <h4>Days</h4>
            </th>
            {days.map((col) => (
              <th
                className={"cell"}
                style={{
                  background:
                    globalUsersData[uid]?.employmentType == "shift"
                      ? "#9e9e9e6b"
                      : col.format("dd").toString() == missionWeekendDays[0] ||
                        col.format("dd").toString() == missionWeekendDays[1]
                      ? "yellow"
                      : "#9e9e9e6b",
                }}
              >
                <h5>{col.format("dd")}</h5>
              </th>
            ))}
            <th colSpan={3} className={"inputText"} style={{ background: "#fff" }}>
              <h5>Total</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={3} className={"titleText"}>
              <h4>Date</h4>
            </th>
            {days.map((col) => (
              <th
                className={"cell"}
                style={{
                  background:
                    globalUsersData[uid]?.employmentType == "shift"
                      ? "#9e9e9e6b"
                      : col.format("dd").toString() == missionWeekendDays[0] ||
                        col.format("dd").toString() == missionWeekendDays[1]
                      ? "yellow"
                      : "#9e9e9e6b",
                }}
              >
                <h5>{col.format("DD-MMM")}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5></h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={3} className={"titleText"}>
              <h4>Time-in</h4>
            </th>
            {daysTimeIn.map((col) => (
              <th
                className={"cell"}
                style={{
                  background:
                    globalUsersData[uid].employmentType == "shift"
                      ? col.workedDay
                        ? "none"
                        : "yellow"
                      : col.MINUTES_IN_DAY == 0
                      ? "yellow"
                      : "none",
                }}
              >
                <h5>{convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5></h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={3} className={"titleText"}>
              <h4>Time-out</h4>
            </th>
            {daysTimeOut.map((col) => (
              <th
                className={"cell"}
                style={{
                  background:
                    globalUsersData[uid].employmentType == "shift"
                      ? col.workedDay
                        ? "none"
                        : "yellow"
                      : col.MINUTES_IN_DAY == 0
                      ? "yellow"
                      : "none",
                }}
              >
                <h5>{convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5></h5>
            </th>
          </tr>

          <tr className={"row"}>
            <th colSpan={3} className={"titleText"}>
              <h4>Worked-Hours</h4>
            </th>
            {TimeOut_Minus_TimeIn.map((d) => (
              <th
                className={"cell"}
                style={{
                  background:
                    globalUsersData[uid].employmentType == "shift"
                      ? d.workedDay
                        ? "none"
                        : "yellow"
                      : d.num == 0
                      ? "yellow"
                      : "none",
                }}
              >
                <h5>{convertToHoursMin(d.num)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5> {convertToHours(totalWorkedHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={1} rowSpan={5} className={"titleText"} style={{ background: "#9e9e9e6b" }}>
              Leave
            </th>

            <th colSpan={2} className={"titleText"}>
              <h5>Annual-Leave</h5>
            </th>
            {daysAnnual.map((col) => (
              <th className={"cell"}>
                <h5>{col.MINUTES_IN_DAY == 0 ? "" : convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalAnnualHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={2} className={"titleText"}>
              <h5>Sick-Leave</h5>
            </th>
            {daysSick.map((col) => (
              <th className={"cell"}>
                <h5>{col.MINUTES_IN_DAY == 0 ? "" : convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalSickHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={2} className={"titleText"}>
              <h5>Other-Leave</h5>
            </th>
            {daysOther.map((col) => (
              <th className={"cell"}>
                <h5>{col.MINUTES_IN_DAY == 0 ? "" : convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalOtherHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={2} className={"titleText"}>
              <h5>Public-Holiday</h5>
            </th>
            {daysPublicHolidays.map((col) => (
              <th className={"cell"}>
                <h5>{col.MINUTES_IN_DAY == 0 ? "" : convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalPublicHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={2} className={"titleText"}>
              <h5>Unpaid-Leave</h5>
            </th>
            {daysUnpaid.map((col) => (
              <th className={"cell"}>
                <h5>{col.MINUTES_IN_DAY == 0 ? "" : convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalUnpaidHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th
              colSpan={3}
              style={{
                border: "1px solid black",
                width: "25%",
                padding: "5px",
                background: "#fff",
                textAlign: "center",
              }}
            >
              <h4>Total</h4>
            </th>
            {daysTotal.map((col) => (
              <th className={"cell"}>
                <h5>{convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5> {convertToHours(hours)}</h5>
            </th>
          </tr>
          <tr>
            <th colSpan={5} className={"titleText"}>
              <h4>Hours Allocation</h4>
            </th>
            <th colSpan={15} className={"titleText"}>
              <h4>Notes</h4>
            </th>
            <th colSpan={15} className={"titleText"}>
              <h4>Human Resources Department/Checked and Verified By:</h4>
            </th>
          </tr>
          <tr>
            <th className={"titleText"} style={{ width: "5%" }}>
              <h6>Projects</h6>
            </th>
            <th className={"titleText"} style={{ width: "5%" }}>
              <h6>budgetLine</h6>
            </th>
            <th className={"titleText"} style={{ width: "5%" }}>
              <h6>Contracting Percentage</h6>
            </th>
            <th className={"titleText"} style={{ width: "4%" }}>
              <h6>Allocation Percentage</h6>
            </th>
            <th colSpan={1} className={"titleText"}>
              <h6>Worked Hours</h6>
            </th>
            <th colSpan={15} rowSpan={2 + projectsArray.length} className={"cell"} style={{ width: "5%" }}></th>
            <th colSpan={15} rowSpan={2 + projectsArray.length} className={"cell"} style={{ width: "5%" }}></th>
            {/* {days.map((col) => (
              <th className={"cellEmpty"}>
                
              </th>
            ))} */}
          </tr>
          {projectsArray.map((p: any) => {
            const start = moment(p.startDate.toDate()).startOf("day");
            const end = moment(p.endDate.toDate()).endOf("day");
            const totalHoursProject = _.sumBy(daysTotal, (d: any) => {
              if (moment(d.r.toDate()).isBetween(start, end, undefined, "[]")) {
                return (d.MINUTES_IN_DAY * p.percentage) / 100;
              } else {
                return 0;
              }
            });
            return (
              <tr>
                <th className={"projectInput"}>
                  <h5>{projectSN.docs.find((d) => d.id == p.project)?.data().projectCode}</h5>
                </th>
                <th className={"projectInput"}>
                  <h5>{budgetLinesSN.docs.find((d) => d.id == p.budgetLine)?.data().budgetLine}</h5>
                </th>
                <th className={"projectInput"}>
                  <h5>{idaAllocationPercentage + "%"}</h5>
                </th>
                <th className={"projectInput"}>
                  <h5>{p.percentage + "%"}</h5>
                </th>
                {/* {daysTotal.map((col) => {
                  if (moment(col.r.toDate()).isBetween(start, end, undefined, "[]")) {
                    let MINUTES_IN_DAY = (col.MINUTES_IN_DAY * p.percentage) / 100;

                    return (
                      <th className={"cell"}>
                        <h5>{MINUTES_IN_DAY == 0 ? "" : convertToHoursMin(MINUTES_IN_DAY)}</h5>
                      </th>
                    );
                  } else {
                    return (
                      <th className={"cell"}>
                        <h5></h5>
                      </th>
                    );
                  }
                })} */}
                <th colSpan={1} className={"inputText"} style={{ background: "#fff" }}>
                  <h5> {convertToHours(totalHoursProject)}</h5>
                </th>
              </tr>
            );
          })}
          <tr>
            <th colSpan={2} className={"inputText"} style={{ background: "#fff" }}>
              <h5>TOTAL HOURS ALLOCATED</h5>
            </th>
            <th className={"inputText"} style={{ background: "#fff" }}></th>

            {/* {newTotalCell.map((min) => {
              return (
                <th className={"cell"}>
                  <h5>{convertToHoursMin(min)}</h5>
                </th>
              );
            })} */}
            <th className={"projectInput"} style={{ background: "#fff" }}>
              <h5>{percentage + "%"}</h5>
            </th>
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5> {convertToHours(TOTAL_PROJECT_CELL)}</h5>
            </th>
          </tr>

          {/* <tr className={"row"}>
            <th colSpan={12} className={"inputText"}>
              <h4></h4>
            </th>
            <th colSpan={8} className={"titleText"}>
              <br />

              <h4>Human Resources Department</h4>
              <h4>Checked and Verified By:</h4>
              <br />
            </th>
            <th colSpan={15} className={"inputText"}>
              <h4></h4>
            </th>
          </tr> */}
        </table>

        <br />
      </div>
    </div>
  );
}
