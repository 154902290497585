import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import firebase, { auth } from "firebase/app";
import { Spin, Button, message, Descriptions, Badge, Input, Select, Popconfirm, Icon, DatePicker } from "antd";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import Discussion from "../../discussion/discussion";
import { orderRef, userRef, projectRef, discussionsRef, notificationsRef } from "../../db/collectionsRef";
import FileUploader from "react-firebase-file-uploader";
import moment from "moment";
import EditOrder from "../models/EditOrder";
import contract from "./contract.docx";
import { format } from "../../db/dateFormat";
import { useTranslation } from "react-i18next";
import { generateAndUploadWord } from "./generateAndUploadWord";
const storage = firebase.storage();

const db = firebase.firestore();
const { Option } = Select;

export default function OrderDetails() {
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  const { t } = useTranslation();
  const history = useHistory();
  const [uidConfirmation, setUidConfirmation] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expiryDate, setExpiryDate] = useState(moment());
  const [dateConfirmed, setDateConfirmed] = useState(false);
  const [trackingInput, setTrackingInput] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadingContract, setUploadingContract] = useState(false);

  const [file, setFile] = useState("");
  const [loading2, setLoading2] = useState(false);

  const id = useRouteMatch<{ id: string }>().params.id;
  const [orderSnapshot, error] = useDocument(orderRef.doc(id));

  const [usersSnapshot] = useCollection(userRef);

  const [discussionSN] = useCollection(discussionsRef.where("orderId", "==", id));
  const [notificationSN] = useCollection(notificationsRef.where("orderId", "==", id));
  const [usersAdminSN] = useCollection(userRef.where("adminLaw", "==", true));

  const uid = auth().currentUser!.uid;
  const [userAdminSN] = useDocument(userRef.doc(uid));

  const editProject = () => {
    history.push(`/project/${id}/edit`);
  };

  const notificationSNObject = (notificationSN === undefined ? [] : notificationSN.docs).map((doc) => {
    return {
      orderId: doc.data().orderId,
      id: doc.id,
    };
  });
  const discussionSNObject = (discussionSN === undefined ? [] : discussionSN.docs).map((doc) => {
    return {
      orderId: doc.data().orderId,
      id: doc.id,
    };
  });

  const deleteOrder = async () => {
    history.push(`/`);
    await discussionSNObject
      .filter((e) => e.orderId === id)
      .map((x) => x.id)
      .forEach((docId) => {
        discussionsRef.doc(docId).delete();
      });
    await notificationSNObject
      .filter((e) => e.orderId === id)
      .map((x) => x.id)
      .forEach((docId) => {
        notificationsRef.doc(docId).delete();
      });
    const promise = db.collection("orders").doc(id).delete();

    promise.then(() => {
      message.success(t("general.successfulDelete"));
      console.log("Document successfully deleted!");
      // location.reload()
    });
    promise.catch((error: any) => {
      console.error("Error removing document: ", error);
    });
  };

  const enterLoading = () => {
    setLoading(true);
  };

  if (!orderSnapshot) {
    return <Spin size="large" />;
  }

  const data = orderSnapshot!.data()!;
  const userId = data.manager;
  const orderId = id;
  const orderType = orderSnapshot?.data()?.orderType;
  const firstTime = orderSnapshot?.data()?.firstTime;
  const status = orderSnapshot?.data()?.status;
  const statusBadge =
    status == "Received" || status == "Temporarily Active"
      ? "warning"
      : status == "Processing"
      ? "processing"
      : status == "Rejected" || status == "Expired"
      ? "default"
      : status == "Approved and Waiting for Payment" || status == "Active"
      ? "success"
      : "error";
  const createNotification = (status: string, kind: string) => {
    notificationsRef.add({
      notifyTo: userId,
      notifyFrom: uid,
      orderId: orderId,
      read: false,
      kind: kind,
      content: `Yor order status has been changed to "${status}"!`,
      postedAt: new Date(),
    });
  };
  const orderStorageRef = firebase.storage().ref(data.manager);
  const dateFormat = "YYYY/MM/DD";
  const admin = userAdminSN?.data()?.adminLaw;

  return (
    <div style={{ marginTop: "10px" }}>
      {modalState ? <EditOrder modalState={modalState} setModalState={setModalState} orderId={id} /> : null}

      {/* //------------------- */}
      <Descriptions
        size={"default"}
        title={`${t("general.workPermit")} (${orderType})`}
        layout="horizontal"
        bordered={true}
        column={{ xs: 8, sm: 6, md: 4 }}
      >
        <Descriptions.Item label={t("general.requestedAt")}>
          {moment(data.requestedAt.toDate()).format(format)}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.status")}>
          <Badge status={statusBadge} text={status} />
        </Descriptions.Item>
        {status == "Active" ? (
          <Descriptions.Item label={t("general.uploadCard")} span={2}>
            {data.imgURL == "" ? (
              <div>
                <label
                  style={{
                    background: "rgb(24 144 255)",
                    padding: "5%",
                    marginRight: "3%",
                    borderColor: "rgb(24 144 255)",
                    borderRadius: "6px",
                    color: "white",
                  }}
                >
                  <Icon type="upload" />
                  <span style={{ padding: "2%" }}>{t("general.upload")}</span>
                  <FileUploader
                    hidden
                    randomizeFilename
                    storageRef={orderStorageRef}
                    onUploadStart={() => setUploading(true)}
                    onUploadSuccess={async (fileName: string) => {
                      setUploading(false);
                      setFile(fileName);

                      const downloadURL = await orderStorageRef.child(fileName).getDownloadURL();
                      await orderRef
                        .doc(id)
                        .update({
                          imgURL: downloadURL,
                        })
                        .then(async () => {
                          await message.success(t("general.successfulUpload"));
                        });
                    }}
                  />
                </label>
                {uploading ? <Spin indicator={antIcon} /> : null}
              </div>
            ) : null}
            {data.imgURL != "" ? (
              <div>
                <label style={{ background: "white", padding: "5%", marginRight: "2%", borderRadius: "6px" }}>
                  Uploaded
                </label>
                <Icon
                  style={{ color: "red", marginBottom: "0%" }}
                  type="close-circle"
                  onClick={() => {
                    firebase
                      .storage()
                      .refFromURL(data.imgURL)
                      .delete()
                      .then(() => {
                        orderRef.doc(id).update({
                          imgURL: "",
                        });
                      });
                  }}
                />
              </div>
            ) : null}
          </Descriptions.Item>
        ) : null}
        {status == "Expired" || status == "Temporarily Active" ? (
          <Descriptions.Item label={t("general.renewalRequest")}>
            {status == "Expired" || status == "Temporarily Active" ? (
              <Popconfirm
                title={t("general.areUSure")}
                onConfirm={() => {
                  orderRef
                    .doc(id)
                    .update({
                      status: "Submitted",
                      requestedAt: new Date(),
                      renewalRequestDate: new Date(),
                      startFreezeDate: "",
                      endFreezeDate: "",
                      imgURL: "",
                      signedContractURL: "",
                      contractURL: "",
                    })
                    .then(() => {
                      const status = "Submitted";
                      createNotification(status, "");
                    })
                    .then(() => {
                      usersAdminSN?.docs.map((doc: any) => {
                        const adminID = doc.id;
                        notificationsRef.add({
                          notifyTo: adminID,
                          notifyFrom: auth().currentUser!.uid,
                          orderId: id,
                          read: false,
                          kind: "newRequest",
                          content: "New order has been requested!",
                          postedAt: new Date(),
                        });
                      });
                    });
                  message.warning("Attention!: Download new contract and put your signature and upload it again!", 100);
                }}
                onCancel={() => console.log("cancel")}
                okText="Yes"
                cancelText="No"
              >
                <Button>{t("general.resubmit")}</Button>
              </Popconfirm>
            ) : null}
          </Descriptions.Item>
        ) : null}
        {admin == true && status != "Canceled" && status != "Expired" && status != "Temporarily Active" ? (
          <Descriptions.Item label={t("general.statusControl")} span={1}>
            {status == "Submitted" ? (
              <Popconfirm
                title={t("general.areUSure")}
                onConfirm={() => {
                  orderRef
                    .doc(id)
                    .update({
                      status: "Received",
                      receivedDate: new Date(),
                    })
                    .then(async () => {
                      const status = "Received";
                      createNotification(status, "");
                      await generateAndUploadWord(contract, data, orderId, uid);
                    });
                }}
                onCancel={() => console.log("cancel")}
                okText="Yes"
                cancelText="No"
              >
                <Button>{t("general.receiveDoc")}</Button>
              </Popconfirm>
            ) : null}
            {status == "Received" ? (
              <Popconfirm
                title={t("general.areUSure")}
                onConfirm={() => {
                  orderRef
                    .doc(id)
                    .update({
                      status: "Processing",
                      sendDocDate: new Date(),
                    })
                    .then(() => {
                      const status = "Processing";
                      createNotification(status, "");
                    });
                }}
                onCancel={() => console.log("cancel")}
                okText="Yes"
                cancelText="No"
              >
                <Button>{t("general.sendDoc")}</Button>
              </Popconfirm>
            ) : null}
            {status == "Processing" ? (
              <div>
                <Popconfirm
                  title={t("general.areUSure")}
                  onConfirm={() => {
                    orderRef
                      .doc(id)
                      .update({
                        status: "Rejected",
                        rejectedDate: new Date(),
                      })
                      .then(() => {
                        const status = "Rejected";
                        createNotification(status, "");
                      });
                  }}
                  onCancel={() => console.log("cancel")}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ margin: "1%" }} type="danger">
                    {t("general.rejected")}
                  </Button>
                </Popconfirm>

                <Popconfirm
                  title={t("general.areUSure")}
                  onConfirm={() => {
                    orderRef
                      .doc(id)
                      .update({
                        // trackingNumber: trackingNumber,
                        status: "Approved and Waiting for Payment",
                        approvedDate: new Date(),
                      })
                      .then(() => {
                        const status = "Approved and Waiting for Payment";
                        createNotification(status, "");
                        // setTrackingInput(false);
                      });
                  }}
                  onCancel={() => console.log("cancel")}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary">{t("general.approved")}</Button>
                </Popconfirm>

                <Popconfirm
                  title={t("general.areUSure")}
                  onConfirm={() => {
                    setTrackingInput(true);
                  }}
                  onCancel={() => console.log("cancel")}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginLeft: "0.5%", marginRight: "0.5%" }} type="primary">
                    {t("general.trackingNumber")}
                  </Button>
                </Popconfirm>
              </div>
            ) : null}
            {trackingInput && admin ? (
              <div style={{ paddingTop: "1%" }}>
                <Input
                  placeholder="Tracking Number"
                  style={{ width: "66%" }}
                  value={trackingNumber}
                  onChange={(e) => setTrackingNumber(e.target.value)}
                />
                <Button
                  onClick={() => {
                    if (trackingNumber == "") {
                      alert("Please Tracking number should not be Empty!");
                    } else {
                      orderRef
                        .doc(id)
                        .update({
                          trackingNumber: trackingNumber,
                        })
                        .then(() => setTrackingInput(false));
                    }
                  }}
                >
                  Confirm
                </Button>
              </div>
            ) : null}
            {status == "Approved and Waiting for Payment" ? (
              <Popconfirm
                title={t("general.areUSure")}
                onConfirm={() => {
                  orderRef
                    .doc(id)
                    .update({
                      status: "Canceled",
                      canceledDate: new Date(),
                    })
                    .then(() => {
                      const status = "Canceled";
                      createNotification(status, "");
                    });
                }}
                onCancel={() => console.log("cancel")}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ background: "rgba(0, 0, 0, 0.25)", color: "white" }}>{t("general.canceled")}</Button>
              </Popconfirm>
            ) : null}
            {status == "Active" ? (
              <div>
                <Popconfirm
                  title={t("general.areUSure")}
                  onConfirm={() => {
                    orderRef
                      .doc(id)
                      .update({
                        status: "Frozen",
                        startFreezeDate: new Date(),
                      })
                      .then(() => {
                        const status = "Frozen";
                        createNotification(status, "");
                      });
                  }}
                  onCancel={() => console.log("cancel")}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary">{t("general.freeze")}</Button>
                </Popconfirm>
                <Popconfirm
                  title={t("general.areUSure")}
                  onConfirm={() => {
                    orderRef
                      .doc(id)
                      .update({
                        status: "Stopped",
                        leaveJobDate: new Date(),
                      })
                      .then(() => {
                        const status = "Stopped";
                        createNotification(status, "");
                      });
                  }}
                  onCancel={() => console.log("cancel")}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ background: "rgba(0, 0, 0, 0.25)", color: "white" }}>{t("general.leaveJob")}</Button>
                </Popconfirm>
              </div>
            ) : null}
            {status == "Frozen" ? (
              <Popconfirm
                title={t("general.areUSure")}
                onConfirm={() => {
                  orderRef
                    .doc(id)
                    .update({
                      status: "Active",
                      endFreezeDate: new Date(),
                    })
                    .then(() => {
                      const status = "Active";
                      createNotification(status, "");
                    });
                }}
                onCancel={() => console.log("cancel")}
                okText="Yes"
                cancelText="No"
              >
                <Button>{t("general.unfreeze")}</Button>
              </Popconfirm>
            ) : null}

            {status == "Rejected" ? <a>{t("general.orderHasBeenRejected")}</a> : null}
            {status == "Stopped" ? <a>{t("general.staffOutOfWork")}</a> : null}
          </Descriptions.Item>
        ) : null}
        {status == "Approved and Waiting for Payment" ? (
          <Descriptions.Item label={t("general.expiryDate")} span={1}>
            <DatePicker value={expiryDate} format={dateFormat} onChange={(e) => setExpiryDate(e)} />
            <Button
              type="primary"
              onClick={() => {
                orderRef
                  .doc(id)
                  .update({
                    permitExpiryDate: expiryDate.toDate(),
                    status: "Active",
                    activeDate: new Date(),
                  })
                  .then(async () => {
                    await createNotification("Active", "");
                  });
              }}
            >
              {t("general.confirm")}
            </Button>
          </Descriptions.Item>
        ) : null}
      </Descriptions>
      {/* //------------------- */}
      <div id="printing">
        {orderType != "" ? (
          <div>
            <Descriptions
              size={"small"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={t("general.firstTime")} span={1}>
                {data.firstTime}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.requestedBy")} span={2}>
                {`${usersSnapshot?.docs?.find((e) => e.id == data.manager)?.data().firstName} ${
                  usersSnapshot?.docs?.find((e) => e.id == data.manager)?.data().lastName
                }`}
              </Descriptions.Item>
              {data.trackingNumber == "" ? null : (
                <Descriptions.Item label={t("general.trackingNumber")} span={1}>
                  {data.trackingNumber}
                </Descriptions.Item>
              )}
              {
                <Descriptions.Item label={t("general.permitExpiryDate")} span={2}>
                  {moment(data.permitExpiryDate.toDate()).format(format)}
                </Descriptions.Item>
              }
            </Descriptions>
            <Descriptions
              size={"small"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              {/* //------------------- */}
              <Descriptions.Item label={t("general.TC")} span={3}>
                {data.TC}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.firstName")} span={3}>
                {data.firstName}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.lastName")} span={3}>
                {data.lastName}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.fatherName")} span={3}>
                {data.fatherName}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.motherName")} span={3}>
                {data.motherName}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.gender")} span={3}>
                {data.gender}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.birthPlace")} span={3}>
                {data.birthPlace}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.birthDate")} span={3}>
                {moment(data.birthDate.toDate()).format(format)}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.marriedStatus")} span={3}>
                {data.marriedStatus}
              </Descriptions.Item>
              {orderType != "Turk Vatandasi" ? (
                <Descriptions.Item label={t("general.nationality")} span={3}>
                  {data.nationality}
                </Descriptions.Item>
              ) : null}
              {/* //------------------- */}
              {orderType != "AFAD Kart" ? (
                <Descriptions.Item label={t("general.femaleChildrenNumber")} span={3}>
                  {data.femaleChildrenNumber}
                </Descriptions.Item>
              ) : null}
              {orderType != "AFAD Kart" ? (
                <Descriptions.Item label={t("general.maleChildrenNumber")} span={3}>
                  {data.maleChildrenNumber}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label={t("general.wifeNationality")} span={3}>
                {data.wifeNationality}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.wifeFirstName")} span={3}>
                {data.wifeFirstName}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.wifeLastName")} span={3}>
                {data.wifeLastName}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.wifeTC")} span={3}>
                {data.wifeTC}
              </Descriptions.Item>
              {/* //------------------- */}
              <Descriptions.Item label={t("general.phone")} span={3}>
                {data.phone}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.email")} span={3}>
                {data.email}
              </Descriptions.Item>
              {/* //------------------- */}
              {orderType != "AFAD Kart" ? (
                <Descriptions.Item label={t("general.universityNameAddress")} span={3}>
                  {data.universityNameAddress}
                </Descriptions.Item>
              ) : null}{" "}
              {orderType != "AFAD Kart" ? (
                <Descriptions.Item label={t("general.degree")} span={3}>
                  {data.degree}
                </Descriptions.Item>
              ) : null}
              {orderType != "AFAD Kart" ? (
                <Descriptions.Item label={t("general.degreeField")} span={3}>
                  {data.degreeField}
                </Descriptions.Item>
              ) : null}
              {orderType != "AFAD Kart" ? (
                <Descriptions.Item label={t("general.degreeSpecialization")} span={3}>
                  {data.degreeSpecialization}
                </Descriptions.Item>
              ) : null}
              {/* //------------------- */}
              {orderType == "Ikamet Kart" ? (
                <Descriptions.Item label={t("general.passportNO")} span={3}>
                  {data.passportNO}
                </Descriptions.Item>
              ) : null}
              {orderType == "Ikamet Kart" ? (
                <Descriptions.Item label={t("general.passportExpiryDate")} span={3}>
                  {moment(data.passportExpiryDate.toDate()).format(format)}
                </Descriptions.Item>
              ) : null}
              {orderType == "Ikamet Kart" ? (
                <Descriptions.Item label={t("general.passportAuth")} span={3}>
                  {data.passportAuth}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label={t("general.position")} span={3}>
                {data.position}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.salary")} span={3}>
                {data.salary}
              </Descriptions.Item>
              {orderType == "AFAD Kart" ? (
                <Descriptions.Item label={t("general.cityInSyria")} span={3}>
                  {data.cityInSyria}
                </Descriptions.Item>
              ) : null}
              {orderType == "AFAD Kart" ? (
                <Descriptions.Item label={t("general.degree")} span={3}>
                  {data.degree}
                </Descriptions.Item>
              ) : null}
              {orderType == "AFAD Kart" ? (
                <Descriptions.Item label={t("general.universityNameAddress")} span={3}>
                  {data.universityNameAddress}
                </Descriptions.Item>
              ) : null}
              {orderType == "AFAD Kart" ? (
                <Descriptions.Item label={t("general.degreeFaculty")} span={3}>
                  {data.degreeFaculty}
                </Descriptions.Item>
              ) : null}
              {orderType == "AFAD Kart" ? (
                <Descriptions.Item label={t("general.degreeField")} span={3}>
                  {data.degreeField}
                </Descriptions.Item>
              ) : null}
              {orderType == "AFAD Kart" ? (
                <Descriptions.Item label={t("general.durationInTurkey")} span={3}>
                  {data.durationInTurkey}
                </Descriptions.Item>
              ) : null}
              {orderType == "AFAD Kart" ? (
                <Descriptions.Item label={t("general.femaleChildrenNumber")} span={3}>
                  {data.femaleChildrenNumber}
                </Descriptions.Item>
              ) : null}
              {orderType == "AFAD Kart" ? (
                <Descriptions.Item label={t("general.maleChildrenNumber")} span={3}>
                  {data.maleChildrenNumber}
                </Descriptions.Item>
              ) : null}
              {orderType == "AFAD Kart" ? (
                <Descriptions.Item label={t("general.language")} span={3}>
                  {data.language}
                </Descriptions.Item>
              ) : null}
            </Descriptions>
            <Descriptions
              size={"small"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={t("general.address")} span={6}>
                {data.residenceAddress}
              </Descriptions.Item>
            </Descriptions>
          </div>
        ) : null}
      </div>

      <Descriptions size={"small"} layout="horizontal" bordered>
        <Descriptions.Item label={t("general.actions")}>
          <div>
            {" "}
            {status == "Submitted" || admin ? (
              // && admin == false
              <Button
                type="default"
                style={{ margin: "1%" }}
                onClick={() => {
                  setModalState(true);
                }}
              >
                {t("general.edit")}
              </Button>
            ) : null}
            {uid == "kGtYGGmUJGM63RmUhKBNHhCrHWB2" || uid == "Jmlv0ajWF9RoGv4jWsvosBIBS1s1" ? (
              <Popconfirm
                title={t("general.areUSure")}
                onConfirm={() => {
                  enterLoading();
                  deleteOrder();
                }}
                onCancel={() => console.log("cancel")}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger" style={{ margin: "1%" }} loading={loading} htmlType="submit">
                  {t("general.remove")}
                </Button>
              </Popconfirm>
            ) : null}
            <Button
              type="primary"
              style={{ margin: "1%" }}
              onClick={() => {
                const url = `/print/${id}`;
                window.open(url, "PRINT", "height=700,width=1000");
              }}
              loading={loading}
              htmlType="submit"
            >
              <Icon type="download" />

              {t("general.printOrder")}
            </Button>
            <Button
              type="primary"
              style={{ margin: "1%" }}
              onClick={async () => {
                const contractData = {
                  firstName: data.firstName,
                  lastName: data.lastName,
                  fatherName: data.fatherName,
                  nationality: data.nationality,
                  cityInSyria: data.cityInSyria,
                  birthPlace: data.birthPlace,
                  birthDate: data.birthDate,
                  TC: data.TC,
                  passportNO: data.passportNO,
                  residenceAddress: data.residenceAddress,
                  salary: data.salary,
                  phone: data.phone,
                  email: data.email,
                };
                await generateAndUploadWord(contract, contractData, id, uid);
              }}
              loading={loading}
              htmlType="submit"
            >
              <Icon type="download" />

              {t("general.printContract")}
            </Button>
            {data.contractURL !== "" ? (
              <label
                style={{
                  background: "rgb(24 144 255)",
                  padding: "1%",
                  marginRight: "3%",
                  borderColor: "rgb(24 144 255)",
                  borderRadius: "6px",
                  color: "white",
                }}
              >
                <Icon type="upload" />
                <span style={{ padding: "2%" }}>{t("general.uploadContract")}</span>
                {uploadingContract ? <Spin indicator={antIcon} style={{ color: "#fff" }} /> : null}
                {data.signedContractURL == undefined || data.signedContractURL == "" ? null : (
                  <Icon type="check" style={{ color: "#fff", padding: "1%" }} />
                )}

                <FileUploader
                  hidden
                  storageRef={orderStorageRef}
                  onUploadStart={() => setUploadingContract(true)}
                  onUploadSuccess={async (fileName: string) => {
                    setUploadingContract(false);
                    setFile(fileName);

                    const downloadURL = await orderStorageRef.child(fileName).getDownloadURL();
                    await orderRef
                      .doc(id)
                      .update({
                        signedContractURL: downloadURL,
                        signedContractDate: new Date(),
                      })
                      .then(async () => {
                        await message.success(t("general.successfulUpload"));
                      });
                  }}
                />
              </label>
            ) : null}
            {data.signedContractURL == undefined || data.signedContractURL == "" ? null : (
              <Button
                style={{ margin: "1%" }}
                onClick={async () => {
                  const U = storage.refFromURL(data.signedContractURL);

                  U.getDownloadURL().then((url) => {
                    var link = document.createElement("a");
                    if (link.download !== undefined) {
                      link.setAttribute("href", url);
                      link.setAttribute("target", "_blank");
                      link.style.visibility = "hidden";
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }
                  });
                }}
              >
                <Icon type="download" />
                Download Signed Contract
              </Button>
            )}
          </div>
        </Descriptions.Item>
      </Descriptions>
      <Discussion userId={userId} orderId={orderId} type={"order"} officeId={"GLfu2V44QNF8dKWpz4Xn"} />
    </div>
  );
}
