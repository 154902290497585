import * as React from "react";
import firebase from "firebase";
import Paper from "@material-ui/core/Paper";
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  ViewSwitcher,
  Toolbar,
  Appointments,
  AppointmentTooltip,
  DateNavigator,
  TodayButton,
  AppointmentForm,
  AllDayPanel,
  EditRecurrenceMenu,
  Resources,
  CurrentTimeIndicator,
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState, EditingState, IntegratedEditing, ChangeSet } from "@devexpress/dx-react-scheduler";
import { notificationsRef, userRef, orderRef, meetingRoomList, calendar } from "../../db/collectionsRef";
import { RRule } from "rrule";
import FileUploader from "react-firebase-file-uploader";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, message, Button, Select, Icon } from "antd";
import { map } from "lodash";
import { useState } from "react";
import moment from "moment";
import { auth } from "firebase";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/styles";
import { indigo, blue, teal } from "@material-ui/core/colors";
import { fade } from "@material-ui/core/styles/colorManipulator";
const { Option } = Select;
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { colorsRoom } from "../../db/colors";
import DeleteDialog from "./DeleteConfirmation";
import EditDialog from "./EditConfirmation";
import { useTranslation } from "react-i18next";
import { admins } from "../../db/admin";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Editor from "../Editor/Editor";
import { API, OutputData } from "@editorjs/editorjs";
import { dataToHTML } from "../Editor/constants";
import IconButton from "@material-ui/core/IconButton";
import MomIcon from "@material-ui/icons/NoteAdd"; // Example icon, choose an appropriate one
import AddMOM from "./AddMOM";

const moments = extendMoment(Moment);

const notificationAdd = (members: any[], docId: string) => {
  members.forEach((uid, index) => {
    if (uid === auth().currentUser!.uid) {
      return null;
    } else {
      notificationsRef.add({
        notifyTo: uid,
        notifyFrom: auth().currentUser!.uid,
        orderId: docId,
        read: false,
        kind: "calendarInvitation",
        content: "You have been invited to attend Meeting",
        postedAt: new Date() as any,
      });
    }
  });
};
const TextEditor = (props: any) => {
  if (props.type === "multilineTextEditor") {
    return null;
  }
  return <AppointmentForm.TextEditor {...props} />;
};
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const messages = {
  moreInformationLabel: "",
  detailsLabel: "Appointment Form",
  commitCommand: "Submit",
};
const styles = ({ palette }: Theme) =>
  createStyles({
    appointment: {
      borderRadius: 0,
      borderBottom: 0,
    },
    highPriorityAppointment: {
      borderLeft: `4px solid ${teal[500]}`,
    },
    mediumPriorityAppointment: {
      borderLeft: `4px solid ${blue[500]}`,
    },
    lowPriorityAppointment: {
      borderLeft: `4px solid ${indigo[500]}`,
    },
    weekEndCell: {
      backgroundColor: fade(palette.action.disabledBackground, 0.04),
      "&:hover": {
        backgroundColor: fade(palette.action.disabledBackground, 0.04),
      },
      "&:focus": {
        backgroundColor: fade(palette.action.disabledBackground, 0.04),
      },
    },
    weekEndDayScaleCell: {
      backgroundColor: fade(palette.action.disabledBackground, 0.06),
    },
    text: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    content: {
      opacity: 0.7,
    },
    container: {
      width: "100%",
      lineHeight: 1.2,
      height: "100%",
    },
  });

type AppointmentContentProps = Appointments.AppointmentContentProps & WithStyles<typeof styles>;

const getAppointmentWithValidRRule = (appointment: any) => {
  const { rRule } = appointment;
  if (!rRule) {
    return appointment;
  }
  const rruleOptions = RRule.parseString(rRule);
  if (!rruleOptions.bymonthday || !rruleOptions.byweekday) {
    return appointment;
  }

  const firstMonthDay = rruleOptions.bymonthday[0];
  let weekNumber = Math.ceil(firstMonthDay / 7);
  if (firstMonthDay < 0) {
    weekNumber = -1;
  }

  const nextByWeekDay = rruleOptions.byweekday[0];
  nextByWeekDay.n = weekNumber;

  const validRRuleOptions = {
    ...rruleOptions,
    bymonthday: undefined,
    byweekday: nextByWeekDay,
  };

  const nextRule = new RRule(validRRuleOptions);
  const validRRule = nextRule.toString();

  return {
    ...appointment,
    rRule: validRRule,
  };
};
export default function MeetingCalender() {
  const [isOwner, setIsOwner] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [useList, setUseList] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [changedFields, setChangedFields] = useState();
  const [docId, setDocId] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [spinning, setSpinning] = useState(false);
  const [modalStateMOM, setModalStateMOM] = useState(false);
  const [rowDoc, setRowDoc] = useState({} as any);
  const [MOM, setMOM] = useState("");
  const [visible, setVisible] = useState(false);

  const uid = auth().currentUser!.uid;
  const [calendarSN] = useCollection(
    calendar
      .where("startDate", ">", moment().subtract(3, "months").endOf("day").toDate())
      .where("startDate", "<=", moment().add(3, "months").endOf("day").toDate())
  );
  const [calendarSNList] = useCollection(
    calendar
      .where("roomId", "==", roomId)
      .where("startDate", ">", moment().subtract(3, "months").endOf("day").toDate())
      .where("startDate", "<=", moment().add(3, "months").endOf("day").toDate())
  );

  const [meetingRoomListSN] = useCollection(meetingRoomList.orderBy("createdAt"));
  const [userRefSN] = useCollection(userRef);

  if (!calendarSN || !userRefSN || !calendarSNList) return <Spin />;

  const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }: any) => {
    const agendaObj = appointmentData.agendaObj;
    const agenda = appointmentData.agenda;
    const USERS_ID_FULL_NAME = userRefSN?.docs.map((u) => {
      const fullName = u.data().firstName + " " + u.data().lastName + "-" + u.data()?.resourceNO;
      const uid = u.id;
      const resourceNO = u.data()?.resourceNO;
      return { uid, fullName, resourceNO };
    });
    const uid = auth().currentUser!.uid;
    const [uploadingAttach, setUploadingAttach] = useState(false);
    const agendaAttachStorage = firebase.storage().ref(uid);
    const { t } = useTranslation();

    const agendaFileNames = appointmentData.agendaFileNames == undefined ? [] : appointmentData.agendaFileNames;
    const agendaAttach = appointmentData.agendaAttach == undefined ? [] : appointmentData.agendaAttach;

    const onAgendaAttachChange = (attachName: string, URL: string) => {
      onFieldChange({ agendaFileNames: [...agendaFileNames, attachName], agendaAttach: [...agendaAttach, URL] });
    };
    const onAgendaAttachDelete = () => {
      onFieldChange({ agendaFileNames: [], agendaAttach: [] });
    };
    const onMembersChange = (event: any, newValue: any) => {
      const uids = newValue.map((option: any) => option.uid);
      onFieldChange({ members: uids });
    };

    const AGENDA_TO_EDITOR = (agenda: string, agendaObj: OutputData) => {
      if (agendaObj == undefined) {
        if (agenda == undefined) {
          const emptyAgenda: OutputData = {
            blocks: [],
          };
          return emptyAgenda;
        } else {
          const newAgenda: OutputData = {
            blocks: [
              {
                type: "paragraph",
                data: {
                  text: agenda,
                },
              },
            ],
          };
          return newAgenda;
        }
      } else {
        return agendaObj;
      }
    };

    const onChangeEditor = async (instance: API) => {
      const outputData: OutputData = await instance?.saver?.save();
      onFieldChange({ agenda: dataToHTML(outputData), agendaObj: outputData });
    };
    return (
      <>
        <AppointmentForm.BasicLayout appointmentData={appointmentData} onFieldChange={onFieldChange} {...restProps}>
          <AppointmentForm.Label text="Members" type="title" />

          <Autocomplete
            multiple
            value={USERS_ID_FULL_NAME.filter((option: any) => appointmentData?.members?.includes(option.uid as string))}
            onChange={onMembersChange}
            options={USERS_ID_FULL_NAME}
            getOptionLabel={(option) => option.fullName}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
            renderOption={(props) => {
              return <li {...props}>{props.fullName}</li>;
            }}
          />
          <AppointmentForm.Label text="Agenda" type="title" />

          <Editor
            holder="Agenda"
            data={AGENDA_TO_EDITOR(agenda, agendaObj)}
            setInstance={onChangeEditor}
            minHeight={200}
          />

          {appointmentData.id == undefined || appointmentData.uid == uid ? (
            <div>
              <label
                style={{
                  background: "rgb(100 181 246)",
                  padding: "1%",
                  marginRight: "1%",
                  borderColor: "rgb(100 181 246)",
                  borderRadius: "6px",
                  color: "white",
                }}
              >
                <Icon type="upload" />
                <span style={{ padding: "2%" }}>{t("general.uploadAttach")}</span>
                {uploadingAttach ? <Spin indicator={antIcon} style={{ color: "#fff" }} /> : null}

                <FileUploader
                  hidden
                  storageRef={agendaAttachStorage}
                  onUploadStart={(e: any) => {
                    setUploadingAttach(true);
                  }}
                  onUploadSuccess={async (fileName: string, args: any) => {
                    setUploadingAttach(false);
                    const downloadURL = await agendaAttachStorage.child(fileName).getDownloadURL();
                    onAgendaAttachChange(fileName, downloadURL);
                  }}
                />
              </label>
              <label
                style={{
                  background: "#fff",
                  padding: "1%",
                  marginRight: "1%",
                  border: "solid thin",
                  borderColor: "rgb(100 181 246)",
                  borderRadius: "6px",
                  color: "rgb(100 181 246)",
                }}
              >
                {`Uploaded File Number: ${agendaFileNames.length}`}
              </label>
              <Button onClick={() => onAgendaAttachDelete()} type="danger">
                Delete Attach
              </Button>
            </div>
          ) : null}
        </AppointmentForm.BasicLayout>
      </>
    );
  };

  const locations: any =
    meetingRoomListSN == undefined
      ? []
      : meetingRoomListSN.docs.map((d, index) => {
          return { text: d.data().roomName, id: d.id, color: colorsRoom[index] };
        });

  const resources = [
    {
      fieldName: "roomId",
      title: "Location",
      instances: locations,
      isMain: true,
    },
  ];
  const SnapDocs = useList ? calendarSNList.docs : calendarSN.docs;
  const data: any =
    SnapDocs === undefined
      ? []
      : SnapDocs.map((d) => {
          return {
            MOM: d.data()?.MOM,
            MOMObj: d.data()?.MOMObj,
            id: d.id,
            allDay: d.data().allDay,
            startDate: d.data().startDate.toDate(),
            endDate: d.data().endDate.toDate(),
            startDateNotFormatted: d.data().startDate,
            endDateNotFormatted: d.data().endDate,
            members: d.data().members,
            agenda: d.data().agenda,
            agendaObj: d.data().agendaObj,
            roomId: d.data().roomId,
            title: d.data().title,
            uid: d.data().uid,
            isOriginal: d.data().isOriginal,
            agendaAttach: d.data().agendaAttach,
            agendaFileNames: d.data().agendaFileNames,
            originalId: d.data().originalId,
          };
        });

  const commitChanges = async ({ added, changed, deleted }: ChangeSet) => {
    if (added) {
      const newAdded = getAppointmentWithValidRRule(added);

      const title = newAdded.title == undefined ? "" : newAdded.title;
      const rRule = newAdded.rRule == undefined ? "" : (newAdded.rRule as string);

      const options = RRule.parseString(rRule);
      options.dtstart = new Date(newAdded.startDate);
      const rule = new RRule(options);
      const exDate = newAdded.exDate == undefined ? "" : newAdded.exDate;
      const members = newAdded.members[0] == undefined ? [uid] : [uid, ...newAdded.members];
      const allDay = newAdded.members == undefined ? false : newAdded.allDay;
      const agenda = newAdded.agenda == undefined ? "" : newAdded.agenda;
      const agendaObj = newAdded.agendaObj == undefined ? {} : newAdded.agendaObj;

      const agendaAttach = newAdded.agendaAttach == undefined ? [] : newAdded.agendaAttach;
      const agendaFileNames = newAdded.agendaFileNames == undefined ? [] : newAdded.agendaFileNames;

      const roomId = newAdded.roomId == undefined ? "" : newAdded.roomId;
      const isOnlyOneAppointment = newAdded.rRule == undefined ? true : false;
      const ruleDatesArray =
        newAdded.rRule == undefined
          ? [{ startDate: newAdded.startDate, endDate: newAdded.endDate }]
          : rule
              .all()
              .slice(0, rule.all().length < 50 ? rule.all().length : 50)
              .map((d) => {
                const day = d.getDate();
                const month = d.getMonth();
                const year = d.getFullYear();
                const startDate: Date = new Date(newAdded.startDate);
                const endDate: Date = new Date(newAdded.endDate);
                startDate.setDate(day);
                startDate.setMonth(month);
                startDate.setFullYear(year);
                endDate.setDate(day);
                endDate.setMonth(month);
                endDate.setFullYear(year);
                return { startDate, endDate };
              });
      const lastDateQuery = ruleDatesArray[ruleDatesArray.length - 1].endDate;
      const yesterday = moment(newAdded.startDate).subtract(48, "hours").toDate();
      await calendar
        .where("startDate", ">", yesterday)
        .where("startDate", "<", lastDateQuery)
        .where("roomId", "==", roomId)
        .get()
        .then(async (d) => {
          if (d.empty) {
            const originalId = calendar.doc().id;
            ruleDatesArray.forEach(async (obj, index) => {
              const id = index == 0 ? originalId : calendar.doc().id;
              await calendar
                .doc(id)
                .set({
                  startDate: obj.startDate,
                  endDate: obj.endDate,
                  createdAt: new Date(),
                  title,
                  rRule,
                  members,
                  allDay,
                  agenda,
                  agendaObj,
                  roomId: roomId == "" ? "3Vcicn7y8M5I996bYwSS" : roomId,
                  uid,
                  exDate,
                  agendaAttach,
                  agendaFileNames,
                  isOriginal: index == 0 ? true : false,
                  originalId: originalId,
                  MOMStatus: false,
                  MOMWriter: "",
                  isOnlyOneAppointment,
                  sendMOM: false,
                })
                .then(() => notificationAdd(members, id));
            });
          } else {
            let overlap = true;
            d.forEach((e) => {
              const oldStartDate: any = e.data().startDate.toDate();
              const oldEndDate: any = e.data().endDate.toDate();
              ruleDatesArray.forEach((newObj: any) => {
                const newStartDate: any = newObj.startDate;
                const newEndDate: any = newObj.endDate;
                const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
                const dateNew: [Date, Date] = [newStartDate, newEndDate];
                const rangeOld = moments.range(dateOld);
                const rangeNew = moments.range(dateNew);
                if (rangeOld.overlaps(rangeNew)) {
                  overlap = false;
                  console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
                }
              });
            });
            console.log("is not Overlap:", overlap);
            if (overlap) {
              setSpinning(true);
              let saveTest: number = 0;
              const originalId = calendar.doc().id;
              ruleDatesArray.forEach((obj, index) => {
                const id = index == 0 ? originalId : calendar.doc().id;
                calendar
                  .doc(id)
                  .set({
                    startDate: obj.startDate,
                    endDate: obj.endDate,
                    createdAt: new Date(),
                    title,
                    rRule,
                    members,
                    allDay,
                    agenda,
                    agendaObj,
                    roomId: roomId == "" ? "3Vcicn7y8M5I996bYwSS" : roomId,
                    uid,
                    exDate,
                    isOriginal: index == 0 ? true : false,
                    originalId: originalId,
                    MOMStatus: false,
                    MOMWriter: "",
                    isOnlyOneAppointment,
                    agendaAttach,
                    agendaFileNames,
                    sendMOM: false,
                  })
                  .then(() => notificationAdd(members, id));
                saveTest = saveTest + 1;
                return saveTest;
              });
              if (saveTest === ruleDatesArray.length) {
                setSpinning(false);
                console.log("Done...");
                message.success("Added Successfully!");
              }
            } else {
              alert("Overlaps!!!, this time has been already reserved!");
            }
          }
        });
    }

    if (changed) {
      map(changed, async (changedFields, docId) => {
        const newChanged = getAppointmentWithValidRRule(changedFields);
        console.log(newChanged);
        setDocId(docId);
        setChangedFields(newChanged);
        setModalStateEdit(true);
      });
    }

    if (deleted) {
      setModalState(true);
      await setAppointmentId(deleted.toString());
    }
  };
  const checkOwnerForm = (data: any) => {
    const uidData = data.uid;
    if (uidData !== uid && uidData !== undefined) {
      setIsOwner(true);
    }
  };

  const AppointmentContent = withStyles(styles, { name: "AppointmentContent" })(
    ({ classes, data, ...restProps }: AppointmentContentProps) => {
      const kind = data.isOriginal == true ? <Icon type={"check-circle"} theme="twoTone" /> : <Icon type={"pushpin"} />;
      return (
        <Appointments.AppointmentContent {...restProps} data={data}>
          <div className={classes.container}>
            <div className={classes.text}>
              <b>{data.title}</b>
              <b style={{ float: "right" }}>{kind}</b>
            </div>
            <div className={classes.text}>
              {`${userRefSN.docs.find((d) => d.id == data.uid)?.data().firstName} ${
                userRefSN.docs.find((d) => d.id == data.uid)?.data().lastName
              }`}
            </div>
            <div className={classes.text}>{`${
              meetingRoomListSN?.docs.find((d) => d.id == data.roomId)?.data().roomName
            }`}</div>
          </div>
        </Appointments.AppointmentContent>
      );
    }
  );

  const CustomTooltipLayout = ({ children, appointmentData, ...restProps }: any) => {
    return appointmentData?.members?.includes(uid) ? (
      <AppointmentTooltip.Header {...restProps}>
        {children}
        <IconButton
          onClick={() => {
            const newAppointmentDataForm = {
              ...appointmentData,
              startDate: appointmentData.startDateNotFormatted,
              endDate: appointmentData.endDateNotFormatted,
            };
            setRowDoc(newAppointmentDataForm);
            setMOM(newAppointmentDataForm.MOM);
            setModalStateMOM(true);
          }}
        >
          <MomIcon />
        </IconButton>
      </AppointmentTooltip.Header>
    ) : null;
  };

  return (
    <div className={"calendar"}>
      {modalState ? <DeleteDialog modalState={modalState} setModalState={setModalState} docId={appointmentId} /> : null}
      {modalStateMOM ? (
        <AddMOM modalStateMOM={modalStateMOM} setModalStateMOM={setModalStateMOM} doc={rowDoc} MOM={MOM} />
      ) : null}

      {modalStateEdit ? (
        <EditDialog
          modalState={modalStateEdit}
          setModalState={setModalStateEdit}
          changedFields={changedFields}
          docId={docId}
        />
      ) : null}

      <Select
        style={{ width: "20%", paddingBottom: "1%" }}
        value={roomId == "" ? "Select Room" : meetingRoomListSN?.docs.find((d) => d.id == roomId)?.data().roomName}
        onChange={(e) => {
          setRoomId(e);
          setUseList(true);
        }}
      >
        {meetingRoomListSN?.docs.map((d, index) => {
          return (
            <Option
              style={{
                backgroundColor: colorsRoom[index],
                color: "white",
              }}
              value={d.id}
            >
              <b>{d.data().roomName}</b>
            </Option>
          );
        })}
      </Select>
      <Button
        style={{ marginLeft: "0.5%" }}
        type="primary"
        onClick={() => {
          setUseList(false);
          setRoomId("");
        }}
      >
        Show All
      </Button>
      <Spin spinning={spinning} tip="Loading...">
        <Paper>
          <Scheduler data={data}>
            <ViewState defaultCurrentViewName="Week" />
            <EditingState onCommitChanges={commitChanges} />
            <IntegratedEditing />
            <DayView startDayHour={9} endDayHour={17} />
            <DayView displayName={"Three days"} name="ThreeDays" startDayHour={9} endDayHour={17} intervalCount={3} />
            <WeekView name="work-week" displayName="Work Week" excludedDays={[0, 6]} startDayHour={9} endDayHour={17} />
            <WeekView startDayHour={9} endDayHour={17} />
            <MonthView />
            <EditRecurrenceMenu />
            <Appointments appointmentContentComponent={AppointmentContent} />
            <Toolbar />
            <Resources data={resources} mainResourceName="roomId" />
            <AllDayPanel />
            <AppointmentTooltip
              showCloseButton
              showDeleteButton={false}
              showOpenButton
              headerComponent={CustomTooltipLayout}
              onVisibilityChange={(e) => setVisible(e)}
              visible={visible}
            />
            <AppointmentForm
              messages={messages}
              basicLayoutComponent={BasicLayout}
              textEditorComponent={TextEditor}
              onAppointmentDataChange={checkOwnerForm}
              onVisibilityChange={(v) => {
                if (v == false) {
                  setIsOwner(false);
                }
              }}
              readOnly={admins.includes(uid) ? false : isOwner}
            />

            <DateNavigator />
            <TodayButton />
            <ViewSwitcher />
            <CurrentTimeIndicator shadePreviousCells shadePreviousAppointments />
          </Scheduler>
        </Paper>
      </Spin>
    </div>
  );
}
